import React, { useEffect, useState } from "react";
import { Box, Paper, ThemeProvider, useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useStyle } from '../styles/stylemenu';
import PrimarySearchAppBar from "../components/AppBar";
import { Navigation } from "../components/Navigation";
import { routesAdapter } from "../configs/routes";
import { useDispatch } from "react-redux";
import { getIdToken } from "../authConfig";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { addDetails, deletedDetailObj } from "../store/features/Equipment Detail/slice";
import { rmObject, setObjects, updateObject } from "../store/features/Equipment/equipmentSlice";
import { deleteMapObjects, setMapObjects } from "../store/features/mapObjectsSlice";
import { addTelemetry } from "../store/features/Telemetry/telemetrySlice";
import { setNotification } from "../store/features/notificationSlice";

const Home = () => {

      // define theme
      const theme = createTheme({
          palette: { primary: { light: "#63b8ff", main: "#0989e3",dark: "#005db0", contrastText: "#000",},
                    secondary: { main: "#4db6ac",light: "#82e9de",dark: "#00867d",contrastText: "#000",},
      }});
      const classes = useStyle();
      const [open, setOpen] = useState(true);
      const dispatch = useDispatch();   

      useEffect(()=>{        
  
          const token = getIdToken();
    
          const hubConnection = new HubConnectionBuilder()
              .withUrl("https://api.mwcloud.no/scanwater/prepdash/", {skipNegotiation: false, 
              accessTokenFactory:() => token.then(async (x) =>{
                  return await x.idToken
                }) , 
              headers:{ "Ocp-Apim-Subscription-Key": "3d15850fbc5641029aa1aa05acd431cb"}})
              .withAutomaticReconnect()
              .build();
  
          hubConnection.onreconnecting((err) => { console.log(err)
              if(!!err) {
                  console.error('Reconnecting real-time updates error: ');
              } else {
                  console.log('Reconnecting real-time updates.');
              }
          });
  
          hubConnection.onreconnected(() => console.log('Real-time updates reconnected.'));
  
          hubConnection.onclose((err) => { console.log(err)
              if(!!err) {
                  console.error('Real-time updates disconnected due to error: ');
              } else {
                  console.warn('Real-time updates disconnected.')
              }
          });
          
          hubConnection.on("equipment_updated", (message, id) => {
              dispatch(addDetails(message))
          });
  
          hubConnection.on("equipment_added", (message, id) => {
              dispatch(setObjects(message));
          });
  
          hubConnection.on("equipment_removed", (message, id) => {
              dispatch(deleteMapObjects(message));
              dispatch(rmObject(message));
              dispatch(deletedDetailObj(message));
          });
  
          hubConnection.on("telemetry_updated", (message, id) => {
              dispatch(addTelemetry.AddTelemetry(message))
          });
          hubConnection.on("alerts_updated", (message, id) => {
             
              dispatch(setNotification(message))
             
          });
  
          hubConnection.on("equipment_list_update", (message, id) => {
              dispatch(setMapObjects(message))
              dispatch(updateObject(message))
          });
  
          hubConnection.start().then((r) => {}).catch(
            (r) => {
          });
      }, [dispatch]);
      return (
        <div className={classes.classes.mainRoot}>
          <div className={classes.classes.root}>
              <Router>
                <ThemeProvider theme={theme}>
                    <Box display="flex" flexDirection="row">
                      <Navigation open={open} onOpenChange={setOpen}/>
                      <div className={classes.classes.root}>
                        <PrimarySearchAppBar open={open} onOpenChange={setOpen} />
                        <Paper elevation={3} sx={{ width: "100%", overflowY:"scroll", height:"100%" }}>
                              <Routes>
                              {
                                  routesAdapter().map((routes, index) => {
                                  return (
                                      <Route key={index} path={routes.path} element={<routes.component/>}></Route>
                                  )
                                  })
                              }
                              </Routes>
                          </Paper>
                      </div>
                    </Box>
                </ThemeProvider>
              </Router>
          </div>
        </div>
      );
}

export default Home