import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { MainContainer } from "./utils/maincontainer";
import { FileInput } from "./utils/FileInput";
import { PrimaryButton } from "./utils/primarybutton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { Form } from "./utils/form";
import axios from "axios";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import { useStyle } from "../../styles/styles";
import { postImageData } from "../../Services/ApiService";
import ClearIcon from "@mui/icons-material/Clear";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type UserSubmitForm = {
  image: File;

  onChange: any;
};

type ImageObj = {
  sortOrder: any;
  image: any;
};

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
export const UploadImages = ({ id, images, handleModalClose }: any) => {



  const [files, setFiles] = useState<any[]>([]);
  const [rejected, setRejected] = useState<any[]>([]);
  const { t } = useTranslation();
  const { classes } = useStyle();
  const [errorMessage, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {

    if (acceptedFiles?.length) {
      setFiles((previousFiles) => [
        ...previousFiles,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }

    if (rejectedFiles?.length) {
      setRejected((previousFiles) => [...previousFiles, ...rejectedFiles]);
    }
  }, []);

  useEffect(() => {

    // Revoke the data uris to avoid memory leaks
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name: any) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const removeAll = () => {
    setFiles([]);
    setRejected([]);
  };

  const removeRejected = (name: any) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const handleModal = (x: any) => {

    return handleModalClose(x);
  };

  const handleSort = () => {
    //duplicate items
    let _files = [...files];

    //remove and save the dragged item content
    const draggedItemContent = _files.splice(dragItem.current, 1)[0];

    //switch the position
    _files.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    //update the actual array
    setFiles(_files);
  };

  const onDragStart = (
    e: React.DragEventHandler<HTMLDivElement>,
    index: number
  ) => {

  };

  const onDragEnter = (
    e: React.DragEventHandler<HTMLDivElement>,
    index: number
  ) => {

  };

  const onDragEnd = (
    e: React.DragEventHandler<HTMLDivElement>,
    index: number
  ) => {

  };
  const ig: any = [];
  const [imagess, setImagess] = React.useState(ig);

 
  const finalImages = imagess.sort((a: any, b: any) => a.sortOrder - b.sortOrder);


  images?.map((r: any) => {
    return r.sizeString === "Small" ? ig.push(r) : <></>;
  });

  const handleSubmit = async (e: any) => {
   
    setLoading(true);
    e.preventDefault();


    if (!files?.length) return;
    if (images?.length < 1 || images === undefined) {
      const imagesArray: ImageObj[] = [];
      Object.entries(files).map((res) => {

        const imgData: ImageObj = {
          image: res[1],
          sortOrder: res[0],
        };

        return imagesArray.push(imgData);
      });

      imagesArray.map(async (res: any) => {

      const imageIndex = Object.keys(imagesArray).join(",");

      const reqData = { ...res, id: id };

      return await postImageData(reqData).then((res: any) => {


          if (
            res.response?.status === 500 ||
            res.response?.status === 400 ||
            res.response?.status === 503
          ) {
          
            setTimeout(() => {
              setLoading(false);
            }, 500);
            toast.error(t('upps something went wrong') as string);
          } else {

            setTimeout(() => { setLoading(false); }, 500);
            handleModalClose(false);
            toast.success(t('uploaded successfully') as string);
          }
        });
      });
    }

    else {
      const imagesArray: ImageObj[] = [];
  
      const newArray=[...imagess,...files]
  
      const newFiles = newArray.map((item, idx) => ({item, idx})).filter(x => !x.item.blobId)
     

      Object.entries(newFiles).map((res) => {
     
        const imgData: ImageObj = {
          image: res[1].item,
          sortOrder: res[1].idx,
        };

        return imagesArray.push(imgData);
      });

      imagesArray.map(async (res: any) => {
      
      const imageIndex = Object.keys(imagesArray).join(",");

      const reqData = { ...res,id:id };



        return await postImageData(reqData).then((res: any) => {


          if (
            res.response?.status === 500 ||
            res.response?.status === 400 ||
            res.response?.status === 503
          ) {
            
            setTimeout(() => {
              setLoading(false);
            }, 500);
            toast.error(t('upps something went wrong') as string);
          } else {

            setTimeout(() => { setLoading(false); }, 500);
            handleModalClose(false);
            toast.success(t('uploaded successfully') as string);
          }
        });
      });
    }

  };
  return (
    <MainContainer>
      <h5 className={classes.title}>{t("uploadimage")} </h5>

      <Form onSubmit={handleSubmit}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>{t('dropfiles')} ...</p>
          ) : (
            <p>{t('draganddropfiles')}</p>
          )}
        </div>
        <ul style={{ listStyle: 'auto' }}>
          {files.map((file, index) => (
            <li key={file.name}>
              <div style={{ display: 'flex', paddingTop: '20px' }}>
                <div>
                  <img src={file.preview}
                    alt=""
                    width={100}
                    height={100}
                    style={{ cursor: "move" }}
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                    draggable
                    onDragStart={(e) => (dragItem.current = index)}
                    onDragEnter={(e) => (dragOverItem.current = index)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                  />
                  <Button onClick={() => removeFile(file.name)} color="error">
                    <ClearIcon />
                  </Button>
                </div>
                <p className="mt-2 text-neutral-500 text-[20px] font-medium">
                  {file.name}
                </p>
              </div>


            </li>
          ))}
        </ul>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: "32px" }}>
          <Button type="submit" variant="contained" style={{ margin: "10px 15px 0px 0px", color: "white", backgroundColor: "#00005a", }} className="btn btn-primary" disabled={loading}>
            Upload {loading && <CircularProgress size={14} />}{" "}
          </Button>

          {errorMessage && (
            <div className="error" style={{ color: "green", marginTop: "10%" }}>
              {errorMessage}
            </div>
          )}
        </div>

        {/* Rejected Files */}
        {
          rejected.length ?
            <h3 className="title text-lg font-semibold text-neutral-600 mt-24 border-b pb-3">
              {t('rejectedfiles')}
            </h3> :
            <></>
        }
        <ul className="mt-6 flex flex-col" style={{ listStyle: 'auto' }}>
          {rejected.map(({ file, errors }) => (
            <li key={file.name} className="flex items-start justify-between" style={{ marginTop: "16px" }}>
              <div style={{ display: 'flex', justifyContent: "space-around" }}>
                <div>
                  <p className="mt-2 text-neutral-500 text-sm font-medium">
                    {file.name}
                  </p>
                  <ul className="text-[12px] text-red-400">
                    {errors.map((error: any) => (
                      <li key={error.code}>{error.message}</li>
                    ))}
                  </ul>
                </div>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => removeRejected(file.name)}
                  style={{ marginTop: "10px", height: "50%" }}
                >
                  {t('remove')}
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </Form>
    </MainContainer>
  );
};
