import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import TopSectionBackgroundImg from '../../assets/mw2.jpg';
import { BrandLogo } from "../BrandLogo/brandLogo";
import { Button } from "../Button/button";
import { SignInButton } from "../Button/SignInButton";
import { Marginer } from "../marginer/marginer";

export const deviceSize: any = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
}

const TopSectionContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${TopSectionBackgroundImg}) no-repeat;
  background-position: 0px -150px;
  background-size: cover;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    height: 700px;
    background-position: 0px 0px;
  }
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(38, 70, 83, 0.9);
  display: flex;
  flex-direction: column;
`;

const TopSectionInnerContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const StandoutImage = styled.div`
  width: 44em;
  height: 34em;
  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 155px;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    align-items: center;
  }
`;

const SloganText = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 45px;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 20px;
  };
  text-transform: uppercase;
  font-family: system-ui
`;

const SloganDescription = styled.h4`
  margin: 0;
  line-height: 1.4;
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 18px;
  };
  text-transform: capitalize;
  font-family: system-ui
`;

export function TopSection(props: any) {
  const { children } = props;

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <TopSectionContainer>
      <BackgroundFilter>
        {children}
        <TopSectionInnerContainer>

          <LogoContainer>
            {/* <BrandLogo
              logoSize={isMobile ? 40 : 65}
              textSize={isMobile ? 35 : 55}
            /> */}
            <Marginer direction="vertical" margin={8} />
            <SloganText>Welcome to Malthe Winje Portal</SloganText>
            <SloganDescription>Technology Through 100 yrs</SloganDescription>
            <Marginer direction="vertical" margin={15} />
            <Button size = {isMobile ? 2 : 12}> <SignInButton name={'Sign IN'} /> </Button>
          </LogoContainer>

          <BrandLogo logoSize={isMobile ? 40 : 70} />

        </TopSectionInnerContainer>
      </BackgroundFilter>
    </TopSectionContainer>
  );
}