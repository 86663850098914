import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
      clientId: "6655ef16-77a9-4071-8ebb-65503f9ade6b",
      authority: "https://login.microsoftonline.com/98a64167-3106-414e-855f-b7d362df9e54",
      redirectUri: "https://prepdash.mwcloud.no/",
      postLogoutRedirectUri: "https://prepdash.mwcloud.no/",
    },
    cache: {  
		cacheLocation: 'sessionStorage',  
		storeAuthStateInCookie: false,  
	}, 
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
    resource: "https://graph.microsoft.com"
};

export const tokenRequest = {
    scopes: ["User.Read"],
    resource: "https://graph.microsoft.com"
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

// Create an instance of PublicClientApplication
export const msalInstance = new PublicClientApplication(msalConfig);
export const getIdToken = async () => {  
	const activeAccount = msalInstance.getAllAccounts()[0];  
	const requestConfig = { ...loginRequest, account: activeAccount };  
  
	const response = await msalInstance  
		.acquireTokenSilent(requestConfig)  
		.then((res) => {
     return res
    })  
		.catch((error) => {  
			if (error instanceof InteractionRequiredAuthError) {  
				return msalInstance.acquireTokenPopup(loginRequest).then((res) => res.idToken).catch((error: any) => {
                    return error;
              });  
			} else {
                msalInstance.loginRedirect();
      }
			return error;  
		});
	return response;  
};  

export const axiosInstance = axios.create({  
    baseURL: process.env.REACT_APP_BASE_URL,
	headers: {  
		crossDomain: true,  
	},  
});  

  
axiosInstance.interceptors.request.use(  
	async (config: any) => {  
		try {  
			const token = await getIdToken();
      const expiresOn: any = new Date(token.expiresOn);
      // Get the current date
      const currentDate: any = new Date();
      // Calculate the time difference in milliseconds
      const timeDifference: any = expiresOn - currentDate;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      if(minutes < 10){
       await getIdToken();
      }
      config.headers["Authorization"] = "Bearer " +token.idToken;
			return config;  
		} catch (tokenErr) { 
			throw tokenErr;  
		}  
	},
	(error) => Promise.reject(error)  
); 

axiosInstance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          // Call a function to refresh the token
          const refreshedToken = await getIdToken();
          // Update the authorization header with the new token
          originalConfig.headers.Authorization = "Bearer " +refreshedToken;
          // Retry the original request with the new token
          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

