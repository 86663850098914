import { useEffect, useState } from 'react'
import { getDropDownList } from '../Services/ApiService';

export const DropDownList = () => {

    
    const [fetchedDataList, setFetchedData] = useState(null);
    const [loadingDataList, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getDropDownList();
          setFetchedData(response);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    return { fetchedDataList, loadingDataList };
  
}
