
import { ChevronLeftSharp, Construction, Home, Settings } from '@mui/icons-material';
import { Dashboard, Equipment, EquipmentDetails,EquipmentEdits,Images } from '../pages';

interface MenuItem {
    label: string;
    path: string;
    icon?: any;
    activeIcon? : keyof typeof icons;
    component: any
    status: boolean
  }

  const icons = {
    Home,
    ChevronLeftSharp,
    Settings
  };

export const routesAdapter = () => {
    
  return routes;
}

const routes = [

    {
        label: 'dashboard',
        path: '/',
        key: 'Dashboard',
        icon: Home,
        activeIcon: "<ChevronLeftSharp/>",
        status: true,
        component: Dashboard
    },
    {
        label: 'equipment',
        path: '/equipment',
        key: 'Equipment',
        icon : Construction,
        activeIcon: "Settings",
        status: true,
        component: Equipment
    },
    {
        label: 'Equipment Detail',
        path: '/equipment/detail',
        key:'Equipment Detail',
        icon:Home,
        activeIcon: '',
        status: false,
        component: EquipmentDetails
    },
        {
        label: 'Equipment Edit',
        path: '/equipment/edit',
        icon:Home,
        activeIcon: '',
        status: false,
        component: EquipmentEdits
    },

    {
        label: 'Image Upload',
        path: '/upload/image',
        icon:Home,
        activeIcon: '',
        status: false,
        component: Images
    }
]
