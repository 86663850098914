import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { getAllNotifications } from "../../Services/ApiService";
import { RootState } from "../store";
import { unauthorizedResponseReceived } from "./notificationSlice";

export const GET_NOTIFICATION = 'GET_NOTIFICATION';

export interface PageParam{
    pageNumber: number,
    pageSize: number,
    bypassCache: boolean
}

export const getNotification = createAsyncThunk('getNotification', 
    async(notifsData, {getState, dispatch, rejectWithValue}) => {
        const currentState = getState() as RootState;
       
        if(!currentState?.notification?.data){
            return {
                data: currentState.notification.data,
               
            }   
        };
        try{
            
            const response = await getAllNotifications();
           
            return response;
        }catch(err: any){ console.log(err)
            if(err.response.status === 401){
                dispatch(unauthorizedResponseReceived())
            }
            return rejectWithValue(err)
        }
    }
)
