import { PageContainer } from "./pageContainer/pageContainer";
import { TopSection } from "./TopSection/TopSection";
import styled from "@emotion/styled";
import { deviceSize } from "./responsive/responsive";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props
 */

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${deviceSize.laptop}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 5px;
  }
`;

export const PageLayout = (props: any) => {
    return (
        <>
        <PageContainer>
            <TopSection>
                {/* <Navbar useTransparent /> */}
            </TopSection>
            {/* <InnerPageContainer>
                <Marginer direction="vertical" margin="1em" />
                <ContentContainer>
                    <Services />
                </ContentContainer>
                <Marginer direction="vertical" margin="5em" />
                <SpecialistAd />
            </InnerPageContainer>
            <Footer /> */}
        </PageContainer>
        </>
    );
};