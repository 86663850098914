import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { UploadImages } from "./UploadImages";
import { toArray } from "lodash";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { deleteImage, updateImages } from "../../Services/ApiService";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { useStyle } from "../../styles/styles";
import CloseIcon from '@mui/icons-material/Close';
import { DndProvider } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { isTouchDevice } from "./utils/touch";
import Card from "./Card"
import { stringify } from "querystring";
type ImageObj = {
  sortOrder: any;

  equipmentImageId: any;
};


const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

export const StandardImageList = ({ equipDetails, handleModalClose, newData, }: any) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [orderChanged, setOrderChanged] = React.useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const { classes } = useStyle();
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleCloseCancel = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (equipmentImageId: any) => {
    setLoading(true);
    const reqData = { id: equipmentImageId };

    return await deleteImage(reqData).then((res: any) => {
      if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('anerroroccuredpleasetryagainlater') as string)
      }
      if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('operationfailed') as string)
      }
      if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('nodata') as string)
      }
      if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('the operation is not allowed, due to insufficient rights') as string)
      }
      if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('your session has expired') as string)
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        handleModalClose(true);
        toast.success(t('deleted successfully') as string);

      }
    });
  };

 
  const [imagess, setImagess] = React.useState<any[]|null>(null);

  

  React.useEffect(() => {

    setImagess(equipDetails?.images?.filter((r:any) => r.sizeString === "Small").sort((a: any, b:any) => a.sortOrder - b.sortOrder))

}, [equipDetails?.images]);


  
  const moveImage = (item:any, targetItem:any) => {
  
    setImagess((prev:any) => {
     
      console.log(prev.map((p:any) => p.equipmentImageId))
      const clonedCards = JSON.parse(JSON.stringify([...prev || []])) ;
      const draggedItem = clonedCards.find((c:any) => c.equipmentImageId === item.equipmentImageId);
      const droppedToItem = clonedCards.find((c:any) => c.equipmentImageId === targetItem.equipmentImageId);

      if(!!draggedItem && !!droppedToItem) {
        if(draggedItem.sortOrder !== droppedToItem.sortOrder) {
          setOrderChanged(true);
        }
        const tempOrder = draggedItem.sortOrder;
        draggedItem.sortOrder = droppedToItem.sortOrder;
        droppedToItem.sortOrder = tempOrder;
      }
     
     
      
      /*for(let i = 0; i < clonedCards.length; i++) {
              const first = clonedCards[i];
              const second = prev[i];if
      
              if(first.equipmentImageId !== second.equipmentImageId) {
                setOrderChanged(true);
              }
            }*/
      // const results = JSON.parse(JSON.stringify(clonedCards)) as any[];
      // results.forEach((c, idx) => {
      //   c.sortOrder = idx
      // });

      return clonedCards.sort((a: any, b:any) => a.sortOrder - b.sortOrder);
    });
  };
  const handleSubmit = async () => {
  
    
    setLoading(true);
    
    if (!imagess?.length) return;
      const images: ImageObj[] = [];

    Object.entries(imagess).map((res) => {
    
      const image: any = res[1]

      const imageId = image.equipmentImageId;
      const imgData: ImageObj = {
        equipmentImageId: imageId,
        sortOrder: res[0],
      };

      return images.push(imgData);
    });
    const reqData = { images, id: equipDetails?.objectId };
     return await updateImages(reqData).then((res: any) => {
    if (
        res.response?.status === 500 ||
        res.response?.status === 400 ||
        res.response?.status === 503
      ) {
      
        setTimeout(() => {
          setLoading(false);
        }, 500);
        toast.error(t('upps something went wrong') as string);
      } else {

        setTimeout(() => { setLoading(false); }, 500);
        handleModalClose(false);
        toast.success(t('uploaded successfully') as string);
      }
    });

  };
  if (equipDetails?.images?.length < 1 || equipDetails?.images === undefined) {
    return (
      <UploadImages
        id={equipDetails?.objectId}
        handleModalClose={handleCloseUpload}
      />
    );
  }
  return imagess ? (
    <div>
       <IconButton style={{ alignItems: "right", justifyContent: "right", marginBottom: "2%", marginLeft:"90%" }} onClick={handleModalClose}>
                  <CloseIcon />
            </IconButton>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
        <h5 className={classes.title}>{t("existingimages")}</h5>
        
      </div>
      <Typography variant="subtitle1" style={{color:"rgb(189, 189, 189)",alignContent:"center",justifyItems:"center",marginLeft:"5%"}}>{t("draganddropimagestorearrangeorderofimages")}</Typography>
      <div>
      <Grid container spacing={{ xs: 1.5, md: 1,sm: 1, }} columns={{ xs: 4, sm: 8, md: 12 }} style={{paddingLeft:"20px",paddingRight:"10px"}}>
        {
          imagess.map((image: any, index: any) => (
            <Grid item xs={2} sm={3} md={3} key={image.equipmentImageId}>
              <Button style={{ paddingLeft: "70%" }} color="error" >
              <DeleteForeverIcon onClick={() => handleDelete(image.equipmentImageId)} style={{ paddingLeft: "30%", color: "red" }} />
                <Dialog
                  open={openDelete}
                  onClose={handleCloseCancel}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Delete this equipment?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this image?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseCancel}>Cancel</Button>
                    <Button onClick={() => handleDelete(image.equipmentImageId)} disabled={loading}>
                      OK {loading && <CircularProgress size={14} />}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Button>
             
              <Card
                //src={image.imageUrl}
                key={image.equipmentImageId}
                item={image}
                //index={image.sortOrder}
                //state={imagess}
                moveImage={moveImage}
              />
           
            </Grid>
          ))
        }
         
      </Grid>
      {/* { moveImage()?"yes":"no"} */}
      {
        orderChanged?  (<Button
          type="submit"
          variant="contained"
          style={{
            margin: "10px 15px 0px 0px",
            color: "white",
            marginLeft:"80%",
            backgroundColor: "#00005a",
           
            
          }}
          onClick={() => handleSubmit()}
          className="btn btn-primary"
          disabled={loading}
        >
          {" "}
          {t("submit")} {loading && <CircularProgress size={14} />}{" "}
        </Button>) : (<></>)
      }
      </div>
      <Grid>
        <UploadImages
          id={equipDetails?.objectId}
          images={equipDetails?.images}
          handleModalClose={handleCloseUpload}
        />
      </Grid>

    </div>
  ) : (
    <Card sx={{ maxWidth: 500 }}>
      <Grid item>
        <CircularProgress />
        <h5>Please Wait while we fetch images</h5>
      </Grid>
    </Card>
  );
};


