import { createTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
const theme = createTheme();

export const useStyle = makeStyles()({
  root: {
    backgroundColor: "#eee",
    textAlign: "center",
    cursor: "pointer",
    color: "#333",
    padding: "10px",
    marginTop: "20px",
  },
  icon: {
    marginTop: "16px",
    color: "#888888",
    fontSize: "42px",
  },
  root1: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  root2: {
    margin: theme.spacing(3, 0, 2),
    fontFamily: "Permanent Marker",
    textAlign: "center",
    fontSize: "40px",
    color: "deeppink",
    textShadow: "1px 1px darkmagenta",
  },
  root3: {
    // marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop:"5%",
    width:"100%"
  },
  root4: {
    margin: theme.spacing(3, 0, 2),
  },
});