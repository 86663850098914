 import { forEach } from 'lodash';
import React from 'react';
import { AlarmAwareStatePin, DisconnectedStatePin } from './SvgFiles';

interface MapMarkerProps {
  percent:number;
  hasAlarms: Boolean;
  disconnected: boolean;
}

const MapMarker: React.FC<MapMarkerProps> = ({ percent,hasAlarms, disconnected}: any) => {



 

  return hasAlarms || (!hasAlarms && !disconnected) ? (<AlarmAwareStatePin hasAlarms={hasAlarms} percent={percent} />) :disconnected ? (<DisconnectedStatePin />) : <></>
  
}
export default MapMarker;