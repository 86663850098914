import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Popover, Tooltip} from "@mui/material";
import { useRef, useState } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';

function Profile() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = (logoutType: string) => {
    setAnchorEl(null);

    if (logoutType === "popup") {
      instance.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  } 

  return (
    <>
      <Tooltip arrow title={t('profile')}>
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <AccountCircle style={{ color: "#D9D9D9",width:"40px",height:"40px" }} />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        style={{ marginTop: 40 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleLogout("redirect")} key="logoutRedirect">
                  <ListItemIcon>
                    <ExitToApp style={{ color: "#00005a" }} />
                  </ListItemIcon>
                  <ListItemText primary={t('logout')} style={{ color: "#00005a" }} />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Popover>
    </>
  );
}

export default Profile;
