import { createSlice } from "@reduxjs/toolkit"
import { getDetails } from "./action"

interface IDetailState{
    detailObj: any,
    loading: boolean,
    error: any,
    unauthorized: boolean;
    deleted: boolean;
    updated: any;
}

const initialState: IDetailState = {
    detailObj : {},
    loading : true,
    error : null,
    unauthorized: false,
    deleted: false,
    updated: false

}

export const detailObject = createSlice({
    name: "detailObject",
    initialState,
    reducers:{
        addDetails:(state, action) =>{
            if(state.detailObj && state.detailObj.objectId === action.payload.objectId){ 
                state.detailObj = action.payload;
            }
            state.updated = action.payload.objectId;
        },
        deletedObject:(state, action) => { 
            if(state.detailObj && state.detailObj.objectId === action.payload){
                state.deleted = true;
            }
        },
        clearDetailObj:(state) => {
            state.detailObj = {};
            state.error = false;
        },
        unauthorizedResponse: (state: any) => {
            state.unauthorized = true;
        },
        setStatusToFalse: (state,action) => {
            // Check if the deleted item matches the id of the dispatched object
            if(state.detailObj && state.detailObj.objectId === action.payload){
                state.deleted = false;
            }
        },
        setUpdatedToFalse: (state,action) => {
            // Check if the deleted item matches the id of the dispatched object
            if(state.detailObj && state.detailObj.objectId === action?.payload){
                state.updated = false;
            }
        }
    },
    extraReducers:(builder) => {
        builder.addCase(getDetails.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getDetails.fulfilled, (state, action) => {
            if(state.loading === true){
            state.loading = false;
            state.error = false;
            state.detailObj = action.payload;
            }
        })
        .addCase(getDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
})

export const addDetails = detailObject.actions.addDetails;
export const clearDetailObj = detailObject.actions.clearDetailObj;
export const deletedDetailObj = detailObject.actions.deletedObject;
export const unauthorizedResponse = detailObject.actions.unauthorizedResponse;
export const setDeletedToFalse = detailObject.actions.setStatusToFalse;
export const setUpdatedToFalse = detailObject.actions.setUpdatedToFalse;
export default detailObject.reducer;