import {
    alpha,
    Badge,
    Box,
    Card,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Popover,
    Tooltip,
    Typography
  } from '@mui/material';
  import { useEffect, useRef, useState } from 'react';
  import NotificationsIcon from '@mui/icons-material/Notifications';
  import { styled } from '@mui/material/styles';
  import { getAllNotifications } from '../../../Services/ApiService';
  
  import { formatDistance, subDays } from 'date-fns';
  import { useNavigate } from 'react-router-dom';
  

  const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
      
      .MuiBadge-badge {
          background-color: ${alpha(theme.palette.error.main, 0.1)};
          color: ${theme.palette.error.main};
          min-width: 16px; 
          height: 16px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
  );
  
  
  function NoNotifications() {
  
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<any[]>([]);
    const navigate = useNavigate();
    const handleOpen = (): void => {
      setOpen(true);
    };
  
    const handleClose = (): void => {
      setOpen(false);
    };
  
  
   
    return  (
      <>
        <Tooltip arrow title="Notifications">
          <IconButton color="primary" ref={ref} onClick={handleOpen}>
            <Badge badgeContent={notifications.length} color="error">
              <NotificationsIcon style={{ color: "#000000" }} />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          style={{ height: "100%", width: "100%" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{ p: 2 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" style={{ color: "#00005A" }}>
              Notifications
            </Typography>
          </Box>
          <Divider />
          {notifications.length > 0 && (
            <ul>
              {notifications.map(notification => (
                <List sx={{ p: 0 }}>
                  <ListItem
                    sx={{ p: 2, minWidth: 350, display: { xs: "block", sm: "flex" } }}
                  >
                    <Box flex="1" 
                      sx={{
                        width: 300,
                        backgroundColor: '#ffff',
                        '&:hover': {
                          backgroundColor: 'rgb(246 246 246)',
                          opacity: [0.9, 0.8, 0.7],
                          cursor: "pointer",
                        },
                      }} >
                      <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontWeight: "bold" }}>
                         No Notifications
  
                        </Typography>
  
                      </Box>
                
  
  
                    </Box>
  
                  </ListItem>
                </List>
  
              ))}
            </ul>
          )}
  
        </Popover>
      </>
    );
  }
  
  export default NoNotifications;
  