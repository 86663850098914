import { createAsyncThunk } from "@reduxjs/toolkit";
import { mapInformation } from "../../Services/ApiService";
import { RootState } from "../store";
import { setMapObjects, unauthorizedResponseReceived } from "./mapObjectsSlice";
import { forEach } from 'lodash';

export const GET_MAP_OBJECTS = 'GET_MAP_OBJECTS';

export interface PageParam{
    pageNumber: number,
    pageSize: number,
    bypassCache: boolean
}

export const getMapObjects = createAsyncThunk('getMapObjects', 
    async(userData, {getState, dispatch, rejectWithValue}) =>  {
        const currentState = getState() as RootState;
        if(!currentState?.mapObjects?.data){
            return {
                data: currentState.mapObjects.data,
              
            }   
        };
        try{
            const response = await mapInformation();
            return response;
        }catch(err: any){ console.log(err)
            if(err.response.status === 401){
                dispatch(unauthorizedResponseReceived())
            }
            
            return rejectWithValue(err)
        }
    }
)