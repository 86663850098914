import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useStyle } from "../../styles/styles";
import TextField from "@mui/material/TextField";
import AsyncSelect from "react-select/async";
import { useNavigate } from 'react-router-dom';
import { deleteEquipment, updateEquipmentDetails } from "../../Services/ApiService";
import InputLabel from "@mui/material/InputLabel";
import { useForm, Controller, DefaultValues } from "react-hook-form";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { AppDispatch, RootState } from "../../store/store";
import { useAppDispatch } from "../../hooks/hooks";
import { clearDetailObj, setDeletedToFalse, setUpdatedToFalse } from "../../store/features/Equipment Detail/slice";
import { clearTemporaryObject } from "../../store/features/Telemetry/telemetrySlice";
import { DropDownList } from "../../hooks/DropDownList";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";
import { useSelector } from "react-redux";
import { getObjects } from "../../store/features/Equipment/equipmentAction";
import { clearPaginationData } from "../../store/features/Equipment/equipmentSlice";
interface IFormInputs {
  name: string;
  description: string;
  idTag: string;
  customerId: string;
  vendorId: string;
  locationId: string;
  locationType:string;
  subTypeId: string;
  telemetryEnabled: boolean;
  deviceSerialNumber: string;
  operationState: number;

}

export const EquipmentEdit = ({equipDetails, handleModalClose}: any) => {
  const equipDetail = equipDetails;
  const defaultValues: DefaultValues<IFormInputs> = {
    name: equipDetail?.name,
    description: equipDetail?.description,
    customerId: equipDetail?.customer?.id,
    vendorId: equipDetail?.vendor?.id,
    locationId: equipDetail?.assignedLocation?.id,
    subTypeId: equipDetail?.subType?.id,
    locationType: equipDetail?.assignedLocation?.locationType,
    operationState: equipDetail?.operationState,
    idTag: equipDetail?.idTag,
    deviceSerialNumber: equipDetail?.deviceSerialNumber,
  };
  const { handleSubmit, register, control, watch, formState: { errors } } = useForm<IFormInputs>({ defaultValues });
  const { t } = useTranslation();
  //   usestate
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedVendorValue, setSelectedVendorValue] = useState(null);
  const [inputValueState, setStateValue] = useState();
  const [selectedStateValue, setSelectedStateValue] = useState(null);
  const [inputValue, setValue] = useState();
  const [selectedLocationValue, setSelectedLocationValue] = useState(null);
  const [subType, setSubType] = useState(null);
  const [inputValueVendor, setVendorValue] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { classes } = useStyle();
  const dispatch: AppDispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { fetchedDataList, loadingDataList }: any = DropDownList();
  const [ subTypeValue, setSubTypeValue ] = useState(false);
  const [locationItems, setLocationItems] = useState<any>();
  const [inputValueLocationTy, setLocationTyValue] = useState();
  const [locationName, setLocationName] = useState<any|null>(null);
  const [openLocationName, setOpenLocationName] = useState(false);
  const currentPageNum = useSelector((state:RootState) => state.objects.currentPage);     

  // handle change event of the type dropdown
  const handleTypeChange = (obj: any) => { 
    setOpen(false);
    setSubTypeValue(true);
    equipDetails = {}; 
    setValue(obj.subTypes)
    setTimeout(() => setOpen(true), 1500)
  };

  // Dropdownlist fetching
  const fetchDataCustomer = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.customers
  };
  
  const fetchDataVendor = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.vendors;
  };

  const fetchDataTypes = async () => { 
    if (!fetchedDataList) return [];
      fetchedDataList.types.map((r: any) => {
        if(r.id === equipDetails?.type?.id){
          return setValue(r?.subTypes)
        }
        setOpen(true);
      })
    return await fetchedDataList?.types
  };

  const fetchLocationType = async () => {
    if (!fetchedDataList) return [];
    const x = fetchedDataList?.locationTypes?.filter((r: any) => {
      return r?.id === equipDetail?.assignedLocation?.locationType
    })
    
    const localtionItems = x[0]?.data?.filter((r: any) => {
      return r.customerId === equipDetail?.customer?.id;
    });

    setLocationItems(localtionItems);
    setOpenLocationName(true);
    return await fetchedDataList?.locationTypes.map((x:any) => ({...x, isDisabled: x.data.length === 0}));
  };

  const fetchDataLocation = async () => {
    return await (locationItems as any[]).filter(l => l.customerId === equipDetail?.customer?.id);
  };

  const fetchState = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.states;
  };

  // handle form changes
  const handleInputChange = (value: any) => {
    setValue(value);
  };
  const handleVendorInputChange = (value: any) => {
    setVendorValue(value);
  };
  const handleLocationInputChange = (value: any) => {
    setVendorValue(value);
  };
  const handleStateInputChange = (value: any) => {
    setStateValue(value);
  };
  const handleLocationTyInputChange = (value: any) => {
    setLocationTyValue(value);
  };
  const loadOptions = (searchValue: any, callback: any) => {
    callback(inputValue);
  };
  const handleCloseCancel = () => {
    setOpenDelete(false);
  };
  const handleClickDelete = () => {
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const reqData = { objectId: equipDetail?.objectId };
    return await deleteEquipment(reqData).then((res: any) => {
      if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("An Error Occured, Please try Again Later")
      }
      if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Operation failed")
      }
      if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("No data")
      }
      if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Operation is not allowed, Due to Insufficient Rights")
      }
      if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Your session has expired")
      }
      else {
        setTimeout(() => { setLoading(false); }, 500);
        // handleModalClose(true);
        dispatch(setDeletedToFalse(equipDetails.objectId));
        dispatch(clearTemporaryObject());
        dispatch(clearDetailObj());
        dispatch(clearPaginationData());
        dispatch(getObjects({ pageNumber: currentPageNum, pageSize: 5, bypassCache: false }));
        navigate('/equipment', { state: "Delete Object" });

        toast.success(t('deleted successfully!') as string);
      }
    })
  };

  const onSubmit = async (data: IFormInputs) => {

    setLoading(true);

    const reqData = {  ...data, objectId: equipDetail?.objectId  };

    return await updateEquipmentDetails(reqData).then((res: any) => {
      if (res.statusCode === 201 || res.statusCode === 200 || res.statusCode === 202) {
        dispatch(setUpdatedToFalse(res?.data?.objectId));
        setTimeout(() => { setLoading(false); }, 500);
        handleModalClose(true);
        toast.success(t('submitted successfully') as string);
      } else if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("An Error Occured, Please try Again Later")
      } else if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Operation failed")
      } else if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("No data")
      } else if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Operation is not allowed, Due to Insufficient Rights")
      } else if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage("Your session has expired")
      }
      else {
       
        setTimeout(() => { setLoading(false); }, 500);
        toast.error(t('upps something went wrong') as string);       
      }

    })
  };

  if(loadingDataList) {
    <LoaderComponent/>
  };

  return (
    <div className={classes.registerForm}>
        <IconButton style={{ alignItems: "right", justifyContent: "right", marginBottom: "2%", marginLeft:"90%" }} onClick={handleModalClose}>
                  <CloseIcon />
            </IconButton>
        <form>
          <div className={classes.closeBtn}>
            <h5 className={classes.title}style={{ marginBottom: "20px" }}>
              {t("update")}
            </h5>
          
          </div>

          <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("equipmentname")}</InputLabel>
          <Controller
            control={control}
            {...register("name")}
            defaultValue={equipDetail?.name}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name ? errors.name?.message : ""}
                sx={{ "& .MuiOutlinedInput-root": { "& > fieldset": { borderColor: "black" }, height: 50 } }}
                fullWidth
                size="medium"
                margin="none"
              />
            )}
          />

          <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("equipmentdescription")}</InputLabel>
          <Controller control={control} defaultValue={equipDetail?.description}  {...register("description")}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{"& .MuiOutlinedInput-root": { "& > fieldset": { borderColor: "black" }, height: 50 } }}
                error={!!errors.description}
                helperText={
                  errors.description ? errors.description?.message : ""
                }
                variant="outlined"
                fullWidth
                size="medium"
                margin="none"
              />
            )}
          />
          { loadingDataList ? 
            <Grid pt={2}>
              <LoaderComponent/>
            </Grid>
             :
          
          <Grid container display={'flex'} flexDirection={'column'}>

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("type")}</InputLabel>
                <Controller
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return ( 
                        <AsyncSelect
                        cacheOptions
                        loadOptions={fetchDataTypes}
                        getOptionLabel={(e: any) => `${t(e.name)}`}
                        getOptionValue={(e: any) => e.id}
                        placeholder={`${t(equipDetail?.type.name)} `}
                        styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', marginRight:'3%', height:50 }), }}
                        onChange={handleTypeChange}
                        className={classes.inputFields}
                        defaultOptions /> 

                    );
                  }}
                  name="subTypeId"
                  rules={{ required: true, }}
                />
              </Grid>
              {open ?
                <Grid item xs={12} md={6}>
                  <InputLabel style={{ color: "#494949", fontSize: "18px" , marginTop:"16px"}}>{t("subtype")}</InputLabel>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                      const handleChangeSubType = (subType: any) => {
                        setSubType(subType);
                        onChange(subType?.id);
                      };
                      return (
                        <AsyncSelect
                          defaultOptions
                          value={subType}
                          loadOptions={loadOptions}
                          onChange={handleChangeSubType}
                          getOptionLabel={(e: any) => e.name}
                          getOptionValue={(e: any) => e.id}
                          styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', width: '100%', height: 50 })}}
                          placeholder={subTypeValue ? '' : equipDetail?.subType.name}
                          className={classes.inputFields}
                        />
                      );
                    }}
                    name="subTypeId"
                    rules={{
                      required: true,
                    }}
                  />
                </Grid >
                :
                <Grid display={'flex'}>
                  <LoaderComponent/>
                </Grid>
              }
            </Grid>
            <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("locationtype")}</InputLabel>
            <Controller  control={control}  render={({ field: { onChange, value, name, ref } }) => {
                const handleSelectChange = (selectedOption: any) => {
                  setOpenLocationName(false);
                  setLocationItems(null);

                  setSelectedLocationValue(selectedOption);
                  onChange(selectedOption?.id);
                  const locationNameList = selectedOption?.data?.filter((r: any) => {
                    return r.customerId === equipDetail?.customer?.id;
                  })

                  setLocationItems(locationNameList);
                  setTimeout(() => setOpenLocationName(selectedOption?.data?.length ? true : false), 500)
                };

                return (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedLocationValue}
                    getOptionLabel={(e: any) => e.name}
                    getOptionValue={(e: any) => e.id}
                    loadOptions={ fetchLocationType}
                    onInputChange={handleLocationInputChange}
                    onChange={handleSelectChange}
                    styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', height: 50 })}}
                    placeholder={equipDetail?.assignedLocation.locationTypeString}
                    className={classes.inputFields}
                  />
                );
              }}
              name="locationId"
              rules={{
                required: true,
              }}
            />
             {openLocationName ? (
                <Grid>
                  <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("locationname")}</InputLabel>
                  <Controller  control={control}  render={({ field: { onChange, value, name, ref } }) => {
                      const handleSelectChange = (selectedOption: any) => {
                        setLocationName(selectedOption);
                        onChange(selectedOption?.id);
                      };

                      return (
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={locationName}
                          getOptionLabel={(e: any) => e.name}
                          getOptionValue={(e: any) => e.id}
                          loadOptions={ fetchDataLocation}
                          onInputChange={handleLocationTyInputChange}
                          onChange={handleSelectChange}
                          styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', height: 50 })}}
                          placeholder={equipDetail?.assignedLocation.name}
                          className={classes.inputFields}
                        />
                      );
                    }}
                    name="locationId"
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
             ):(<></>)}
            {/* <InputLabel style={{color: "#494949", fontSize: "18px", marginTop:"16px"}}>{t("customername")}</InputLabel>
            <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                const handleSelectChange = (selectedOption: any) => {
                  setSelectedValue(selectedOption);
                  onChange(selectedOption?.id);
                };

                return (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    {...register("customerId")}
                    value={selectedValue}
                    getOptionLabel={(e: any) => e.name}
                    getOptionValue={(e: any) => e.id}
                    loadOptions={ fetchDataCustomer}
                    onInputChange={handleInputChange}
                    styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', height: 50 }), }}
                    onChange={handleSelectChange}
                    placeholder={equipDetail?.customer.name}
                    className={classes.inputFields}
                  />
                );
              }}
              name="customerId"
              rules={{
                required: true,
              }}
            /> */}

            <InputLabel style={{color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("tankstate")}</InputLabel>
            <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                const handleSelectChange = (selectedOption: any) => {
                  setSelectedStateValue(selectedOption);
                  onChange(selectedOption?.id);
                };

                return (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    {...register("operationState")}
                    value={selectedStateValue}
                    getOptionLabel={(e: any) => e.name}
                    getOptionValue={(e: any) => e.id}
                    loadOptions={fetchState}
                    onInputChange={handleStateInputChange}
                    onChange={handleSelectChange}
                    styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', height: 50 }), }}
                    placeholder={`${t(equipDetail?.operationStateString)} `}
                    
                    className={classes.inputFields}
                  />
                );
              }}
              name="operationState"
              rules={{
                required: true,
              }}
            />
            
            
            <InputLabel style={{ color: "#494949", fontSize: "18px", marginTop:"16px" }}>{t("vendorname")}</InputLabel>
            <Controller control={control}  render={({ field: { onChange, value, name, ref } }) => {
                const handleSelectChange = (selectedOption: any) => {
                  setSelectedVendorValue(selectedOption);
                  onChange(selectedOption?.id);
                };

                return (
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    {...register("vendorId")}
                    value={selectedVendorValue}
                    getOptionLabel={(e: any) => e.name}
                    getOptionValue={(e: any) => e.id}
                    loadOptions={fetchDataVendor}
                    onInputChange={handleVendorInputChange}
                    onChange={handleSelectChange}
                    styles={{ control: (baseStyles, state) => ({ ...baseStyles, borderColor: state.isFocused ? '#007aff' : 'black', height: 50}), }}
                    placeholder={equipDetail?.vendor.name}
                    className={classes.inputFields}
                  />
                );
              }}
              name="vendorId"
              rules={{
                required: true,
              }}
            />

          </Grid>
          }

          <Grid container pt={3}>
            <Grid item xs={6} display={'flex'} justifyContent={'center'}>
              <Button variant="contained" type="submit" style={{
                marginTop: "10px", color: "white",
                backgroundColor: "#00005a",
              }} onClick={handleSubmit(onSubmit)} disabled={loading}>
                {t("update")}
                {loading && (
                  <CircularProgress size={14} />
                )}
              </Button>
              {errorMessage && <div className="error" style={{ color: "red", marginTop: "10%" }}> {errorMessage} </div>}
            </Grid>

            <Grid item xs={6} display={'flex'} justifyContent={'center'}>
              <Button variant="contained" color="error" style={{ marginTop: "10px" }} onClick={handleClickDelete}>
                {t("remove")}
              </Button>
              <Dialog
                open={openDelete}
                onClose={handleCloseCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                {t('deletethisequipment')}?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  {t('areyousureyouwanttodeletethisequipment')}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseCancel}>Cancel</Button>
                  <Button onClick={handleDelete} disabled={loading}>
                    OK  {loading && (
                      <CircularProgress size={14} />
                    )}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid >
          </Grid>
        </form>
    </div>
  );
}



