import React, { ReactNode } from "react";

import { useStyle } from "./style";

interface Props {
  children?: ReactNode;
  onSubmit:any;
  // any props that come into the component
}
export const Form = ({ children, ...props }: Props) => {
  const { classes } = useStyle();

  return (
    <form {...props} className={classes.root1} noValidate>
      {children}
    </form>
  );
};
