import { createSlice } from "@reduxjs/toolkit";
import { getTelemetry } from "./telemetryAction";

interface TelemetryState {
    temporaryObject: any;
    loading: boolean;
    error: any;
  }
  
  const initialState: TelemetryState = {
    temporaryObject: {},
    loading: false,
    error: null,
  };

export const telemetryObject = createSlice({
    name: "telemetryObject",
    initialState,
    reducers:{

        AddTelemetry: (state, action) => {
            if (state.temporaryObject && state.temporaryObject.tankId === action.payload.tankId) { 
                state.temporaryObject = action.payload;
            } else {
            }
          },
          clearTemporaryObject: (state) => {
            state.temporaryObject = null;
            state.error = false;
          },
        unauthorizedResponse: (state: any) => { 
            state.unauthorized = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTelemetry.pending, (state) => {
            state.loading = true;
            state.error = false;
        })
        .addCase(getTelemetry.fulfilled, (state, action) => {
            state.temporaryObject = action.payload;
        })
        .addCase(getTelemetry.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload
        })
    }
})

export const  addTelemetry  = telemetryObject.actions;
export const clearTemporaryObject = telemetryObject.actions.clearTemporaryObject;
export const unauthorizedResponse = telemetryObject.actions.unauthorizedResponse
export default telemetryObject.reducer;
