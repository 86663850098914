import { Badge, Box, Card, CircularProgress, Divider, Grid, IconButton, List, ListItem, Popover, Tooltip, Typography} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NoNotifications from '../Notification/Nonotifs'
import { getNotification } from "../../../store/features/notificationAction"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from "../../../store/store";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

function HeaderNotifications() {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  //Redux
  const dispatch: AppDispatch = useAppDispatch();
  // dispatch(getMapObjects())
  const objectsList = useAppSelector((stateS: RootState) => stateS?.notification);
  const loading = useAppSelector((stateS: RootState) => stateS?.notification?.loading); 

  useEffect(() => {
    dispatch(getNotification())
  }, []);

  const navigateToDetails = (objectId: string) => {
    navigate("/equipment/detail", { state: objectId });
  };

  const toNotify = ( Array.isArray(objectsList?.data) ? objectsList?.data?.filter(res => Object.entries(res.alerts).filter(([n, v]: any[]) => v.isLowLow || v.isLow || v.isHigh || v.isHighHigh).length > 0) || [] : []);
  if (objectsList?.data?.length < 1 || objectsList?.data === undefined) {
    return (
     <NoNotifications/>
    );
  }
  return objectsList?.data ? (
    <>
      <Tooltip arrow title={t('notifications')}>
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <Badge badgeContent={toNotify?.length} color="error">
            <NotificationsIcon style={{ color: "#000000" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        style={{ height: "100%", width: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5" style={{ color: "#00005A",fontSize:"20px" }}>
            {t('notifications')}
          </Typography>
        </Box>
        <Divider />
        {toNotify?.length > 0 && (
          <ul>
            {toNotify.map((res:any, index: any) => (
              <List sx={{ p: 0 }} key={index}>
                <ListItem
                  sx={{ p: 2, minWidth: 350, display: { xs: "block", sm: "flex" } }}
                >
                  <Box flex="1" 
                      onClick={() => navigateToDetails(res.objectId)}
                
                    sx={{
                      width: 300,
                      backgroundColor: '#ffff',
                      '&:hover': {
                        backgroundColor: 'rgb(246 246 246)',
                        opacity: [0.9, 0.8, 0.7],
                        cursor: "pointer",
                      },
                    }} >
                    <Box display="flex" justifyContent="space-between">
                      <Typography sx={{ fontWeight: "bold",fontSize:"16px" }}>
                        {res.name}
                        
                      </Typography>

                    </Box>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                      style={{fontWeight:"14px"}}
                    >
                      {
                        Object.entries(res.alerts).map(([key, value]: any[]) => value?.isLow || value?.isLowLow ?`${t(key)} ${t('islow')}` : value?.isHigh || value?.isHighHigh ? `${t(key)} ${t('ishigh')}` : "").filter(s => !!s).join(", ")
                      }
                      
                      
                    </Typography>


                  </Box>

                </ListItem>
              </List>

            ))}
          </ul>
        )}

      </Popover>
    </>
  ) : (
    <Card sx={{ maxWidth: 500 }}>
      <Grid item>
        <CircularProgress />
        <h5>Please Wait while we fetch notifications</h5>
      </Grid>
    </Card>
  );
}

export default HeaderNotifications;
