import { createSlice } from "@reduxjs/toolkit";
import { getNotification } from "./notificationAction";


interface NotificationState {

    data:any[];
    loading: boolean;
    error: any;
    
    unauthorized: false;
}

const initialState: NotificationState = {

    data:[],
   
    loading: false,
    error: null,
    unauthorized: false,
};

export const notificationSlice = createSlice(
    {
        name:"notification",
        initialState,
        reducers: {
            addNotification: (state: NotificationState, action: any) => {
               
                const newObject = action.payload;

                const foundIdx = state.data.findIndex((eq:any) => eq.objectId === newObject.objectId);
                if(foundIdx === -1) {
                    state.data.push(newObject);
                } else {
                    state.data[foundIdx] = newObject;
                }

              
            
            },





            unauthorizedResponseReceived: (state: any) => {
                state.unauthorized = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getNotification.pending, (state) => {
                    state.loading = true;
                    state.error = false;
                })
                .addCase(getNotification.fulfilled, (state, action) => {
                    
                    if (state.loading === true) {
                        state.loading = false;
                        state.data = action?.payload;

                    }
                })
                .addCase(getNotification.rejected, (state: any, action) => {

                    state.loading = false;
                    state.error = true;
                    state.data = [];
                })
        }
    }
);

export const setNotification = notificationSlice.actions.addNotification

export const unauthorizedResponseReceived = notificationSlice.actions.unauthorizedResponseReceived
export default notificationSlice.reducer;

