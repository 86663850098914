import React, { ReactNode } from "react";
import Container from "@mui/material/Container";
import { useStyle } from "./style";


interface Props {
  children?: ReactNode;
  // any props that come into the component
}
export const MainContainer = ({ children, ...props }: Props) => {
  const {classes} = useStyle();

  return (
    <Container
      className={classes.root3}
      component="main"
      // maxWidth="xs"
      {...props}
    >
      {children}
    </Container>
  );
};
