import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getMapObjects } from "./mapAction";


interface MapObjectState {

    data: any[];
    loading: boolean;
    error: any;
    //mapData: any[];
    unauthorized: false;
}

const initialState: MapObjectState = {

    data: [],
    //mapData: [],
    loading: false,
    error: null,
    unauthorized: false,
};

export const mapObjectSlice = createSlice(
    {
        name: "mapObjects",
        initialState,
        reducers: {
            addObject: (state: MapObjectState, action: any) => {
               
                const newObject = action.payload;

                const foundIdx = state.data.findIndex((eq:any) => eq.objectId === newObject.objectId);
                if(foundIdx === -1) {
                    state.data.push(newObject);
                } else {
                    state.data[foundIdx] = newObject;
                }
            
            },
            deleteObject: (state: any, action: any) => {
           
                const removedItemObjectId = action.payload;

                const newList = state.data.filter((e :any) => e.objectId !== removedItemObjectId);
               //const foundIdx = state.data.

                state.data = newList;
            },
               unauthorizedResponseReceived: (state: any) => {
                state.unauthorized = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getMapObjects.pending, (state) => {
                    state.loading = true;
                    state.error = false;
                })
                .addCase(getMapObjects.fulfilled, (state, action) => {
                    if (state.loading === true) {
                        
                        state.loading = false;
                        state.data = action?.payload;

                    }
                })
                .addCase(getMapObjects.rejected, (state: any, action) => {
                 

                    state.loading = false;
                    state.error = true;
                })
        }
            
        }
    
);

export const setMapObjects = mapObjectSlice.actions.addObject
export const deleteMapObjects = mapObjectSlice.actions.deleteObject
export const unauthorizedResponseReceived = mapObjectSlice.actions.unauthorizedResponseReceived
export default mapObjectSlice.reducer;

