import React from 'react'
import { useLocation } from 'react-router-dom'
import { Step2 } from '../components/EquipementForm/step2';

export const Images = () => {
    const l = useLocation();
  return (
    <Step2 equipDetails={l.state}/>
  )
}
