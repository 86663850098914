import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { postEquipmentDetails } from "../../Services/ApiService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { DropDownList } from "../../hooks/DropDownList";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type UserSubmitForm = {
  name: string;
  description: string;
  telemetryEnabled: boolean;
  idTag: string;
  customerId: string;
  vendorId: string;
  locationId: string;
  subTypeId: string;
  deviceSerialNumber: string;
  operationState: number;
  locationType: number;
};

const Step1 = ({ handleModalClose }: any) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("equipmentnameisrequired")).max(150, t("equipmentnamecanbeatmost128characterslong")),
    description: Yup.string().required(t("equipmentdescriptionisrequired")).max(500,t("descriptioncanbeatmost1024characterslong")),
    idTag: Yup.string().required(t("Idtagisrequired")).max(50,t("Idtagcanbeatmost64characterslong")),
    vendorId: Yup.string().required(t("vendorisrequired")),
    locationId: Yup.string().required(t("locationisrequired")),
    customerId: Yup.string().required(t("ownerisrequired")),
    subTypeId: Yup.string().required(t("typeandsubtypearerequired")),
    locationType: Yup.string().required(t("locationtypeisrequired")),
    operationState: Yup.string().required(t("equipmentstateisrequired")),
    telemetryEnabled: Yup.boolean().optional(),
    deviceSerialNumber: Yup.string().when('telemetryEnabled', {
      is: true,
      then: Yup.string().required(t("IoTdeviceserialnumberisrequiredwhenIoTdeviceischecked")).max(64, t("IoTdeviceserialnumbercanbeatmost64characterslong"))
    })
    // telemetryEnabled: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const { register, handleSubmit, control, watch, formState, resetField } = useForm<UserSubmitForm>({ resolver: yupResolver(validationSchema) });

  //   usestate
  const { errors } = formState;
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [selectedVendorValue, setSelectedVendorValue] = useState(null);
  const [selectedStateValue, setSelectedStateValue] = useState(null);
  const [locationItems, setLocationItems] = useState<any>();
  const [inputValue, setValue] = useState();
  const [subType, setSubType] = useState<any | null>(null);
  const [locationName, setLocationName] = useState<any | null>(null);
  const  [locationType, setLocationType] = useState<any | null>(null);
  let equipmentsDetails: any;
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openSubType, setOpenSubType] = useState(false);
  const [openLocationName, setOpenLocationName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLocationType, setSelectedLocationType] = useState<any|null>(null);

  const { fetchedDataList, loadingDataList }: any = DropDownList();

  useEffect(() => {
    setLoading(false);
  }, []);

  // handle change event of the type dropdown
  const handleTypeChange = (obj: any) => {
    setSubType(null);
    setOpenSubType(false);
    setValue(obj.subTypes);
    setTimeout(() => setOpenSubType(true), 500);
  };

  // handle change event of the subtype dropdown
  const loadOptions = (searchValue: any, callback: any) => {
    callback(inputValue);
  };

  const telemetryEnabled = watch("telemetryEnabled");
  // Dropdownlist fetching
  const fetchDataCustomer = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.customers
  };

  const fetchDataVendor = async () => {
    if (!fetchedDataList) return [];

    return await fetchedDataList?.vendors;
  };

  const fetchDataTypes = async () => {

    if (!fetchedDataList) return [];

    return await fetchedDataList?.types
  };

  const fetchLocationType = async () => {

    if (!fetchedDataList) return [];

    return await fetchedDataList?.locationTypes.map((x:any) => ({...x, isDisabled: x.data.length === 0}));
  };

  const fetchDataLocation = async () => {
    const allLocations = (fetchedDataList.locationTypes as any[]).flatMap(x => x.data.map((l: any) => ({...l, type: x.id})));
    return await (allLocations as any[]).filter(l => l.customerId === selectedValue?.id && l.type === selectedLocationType?.id)
  };

  const fetchState = async () => {
    if (!fetchedDataList) return [];
    return await fetchedDataList?.states;
  };

  const navigate = useNavigate();
  // handle form changes
  const handleInputChange = (value: any) => {
    setValue(value);
  };
  const handleLocationInputChange = (value: any) => {
    setLocationName(value);
  };


  const onSubmit = async (data: UserSubmitForm) => {
    setLoading(true);
    const reqData = { ...data };
    return await postEquipmentDetails(reqData).then((res: any) => {
      equipmentsDetails = res;
      if (res.response?.status === 500) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('anerroroccuredpleasetryagainlater') as string)
      }
      if (res.response?.status === 400) {

        setTimeout(() => { setLoading(false); }, 500);
     
        toast.success(t('uploaded successfully') as string);
      }
      if (res.response?.status === 204) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('nodata') as string)
      }
      if (res.response?.status === 403) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('theoperationisnotallowedduetoinsufficientrights') as string)
      }
      if (res.response?.status === 401) {

        setTimeout(() => { setLoading(false); }, 500);

        setErrorMessage(t('yoursessionhasexpired') as string)
      }
      else {
        setTimeout(() => { setLoading(false); }, 500);
        handleModalClose(true);
        equipmentsDetails = res;
        navigate("/equipment/detail", { state: equipmentsDetails.objectId });
        toast.success(t('submitted successfully') as string);
      }
    });
  };

  const { classes } = useStyle();

  return (
    <div className={classes.registerForm}>
       <IconButton style={{ alignItems: "right", justifyContent: "right", marginBottom: "2%", marginLeft:"90%" }} onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={classes.closeBtn}>
          
          <h5 className={classes.title} style={{ marginBottom: "20px" }}>
            {t("equipmentdetails")}
          </h5>
         
        </div>

        <Controller control={control}  {...register("name")} render={({ field }) => (
          <TextField
            {...field}
            label={t("equipmentname")}
            sx={{
              "& .MuiOutlinedInput-root": { height: 50 },
              "& label": { lineHeight: 2.1 }
            }}
            variant="outlined"
            fullWidth
            size="small"
            margin="none"
          />
        )}
        />
        {errors.name && <Typography align="left" component="p" variant="caption" className={classes.errorStyle}>{errors.name.message}</Typography>}

        <Controller control={control} {...register("description")}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("equipmentdescription")}
              sx={{
                "& .MuiOutlinedInput-root": { height: 50 },
                "& label": { lineHeight: 2.1 },
                marginTop: "16px"
              }}
              variant="outlined"
              fullWidth
              size="small"
              margin="none"
              className={classes.inputFields}
            />
          )}
        />
        {errors.description && (
          <Typography align="left" component="p" variant="caption" className={classes.errorStyle}>{errors.description.message}</Typography>
        )}

        <Controller  {...register("idTag")} control={control} render={({ field }) => (
          <TextField {...field} label={t("idtag")} sx={{
            "& .MuiOutlinedInput-root": { height: 50 }, marginTop: "16px",
            "& label": { lineHeight: 2.1 }
          }}
            variant="outlined"
            fullWidth
            size="small"
            margin="none"
          />
        )}
        />
        {errors.idTag && (
          <Typography align="left" component="p" variant="caption" className={classes.errorStyle}>{errors.idTag.message}</Typography>
        )}

        <Grid container>

          {
            loadingDataList ?
              <Grid container display={'flex'} justifyContent={'center'} pb={2}>
                <LoaderComponent />
              </Grid>
              :
              <Grid container>
                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {

                  const handleSelectChange = (selectedOption: any) => {
                    setSelectedVendorValue(selectedOption);
                    onChange(selectedOption?.id);
                  };
                  return (
                    <div className={classes.dropDownBoxStyle}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        {...register("vendorId")}
                        value={selectedVendorValue}
                        getOptionLabel={(e: any) => e.name}
                        getOptionValue={(e: any) => e.id}
                        loadOptions={fetchDataVendor}
                        onChange={handleSelectChange}
                        placeholder={t("vendorname")}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            lineHeight: "1.4375em",
                            height: 50

                          }),
                        }}
                        className={classes.inputFields}
                      />
                    </div>
                  );
                }}
                  name="vendorId"
                />
                {errors.vendorId && (
                  <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.vendorId.message}</Typography>
                )}
           

  <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                  const handleSelectChange = (selectedOption: any) => {
                    setSelectedValue(selectedOption);
                    setLocationName(null)
                    onChange(selectedOption?.id);
                    setOpenLocationName(false);
                    setTimeout(() => setOpenLocationName(true && !!locationType?.id), 500);
                    setLocationItems([]);
                    setOpenLocationName(false);
                    resetField("locationId");
                    setTimeout(() => setOpenLocationName(!!selectedOption && !!selectedLocationType?.id ? true : false), 500);
                  };

                  return (
                    <div className={classes.dropDownBoxStyle}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        {...register("customerId")}
                        value={selectedValue}
                        getOptionLabel={(e: any) => e.name}
                        getOptionValue={(e: any) => e.id}
                        loadOptions={fetchDataCustomer}
                        onInputChange={handleInputChange}
                        onChange={handleSelectChange}
                        placeholder={t("customername")}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            lineHeight: "1.4375em",
                            height: "50px",
                          }),
                        }}
                        className={classes.inputFields}
                      />
                    </div>
                  );
                }}
                  name="customerId"
                />
                {errors.customerId && (
                  <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.customerId.message}</Typography>
                )}
              

                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {

                  const handleSelectChange = (selectedOption: any) => {
                    setOpenLocationName(false);
                    setLocationItems(null);
                    setLocationName(null)
                    setLocationType(selectedOption);
                    onChange(selectedOption?.id);
                    setLocationItems(selectedOption.data);
                    setSelectedLocationType(selectedOption);
                    resetField("locationId");
                    setTimeout(() => setOpenLocationName(selectedOption.data.length && !!selectedValue?.id ? true : false), 500)

                  };

                  return (
                    <div className={classes.dropDownBoxStyle}>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={fetchLocationType}
                        {...register("locationType")}
                        getOptionLabel={(e: any) => e.name}
                        getOptionValue={(e: any) => e.id}
                        onInputChange={handleLocationInputChange}
                        onChange={handleSelectChange}
                        defaultOptions
                        placeholder={t("locationtype")}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            lineHeight: "1.4375em",
                            height: "50px",
                          }),
                        }}
                        className={classes.inputFields}
                      />
                    </div>
                  );
                }}
                  name="locationType"
                />
                {errors.locationType && (
                  <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.locationType.message}</Typography>
                )}

                {openLocationName ? (<>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => {
                      const handleChangeLocation = (locationName: any) => {
                        setLocationName(locationName);
                        onChange(locationName?.id);
                      };

                      return (
                        <div className={classes.dropDownBoxStyle}>
                          <AsyncSelect
                            defaultOptions
                            value={locationName}
                            loadOptions={fetchDataLocation}
                            onChange={handleChangeLocation}
                            getOptionLabel={(e: any) => e.name}
                            getOptionValue={(e: any) => e.id}
                            placeholder={t("locationname")}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                lineHeight: "1.4375em",
                                height: "50px",
                              }),
                            }}
                            className={classes.inputFields}
                          />
                        </div>
                      );
                    }}
                    name="locationId"
                  />
                  {errors.locationId && (
                    <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.locationId.message}</Typography>
                  )}
                </>
                ) : (
                  <></>
                )}

               



                <Grid container sx={{ marginTop: "16px" }}>
                  <Grid item xs={openSubType ? 6 : 12}>
                    <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <AsyncSelect
                          cacheOptions
                          loadOptions={fetchDataTypes}
                          {...register("subTypeId")}
                          getOptionLabel={(e: any) => `${t(e.name)}`}
                          getOptionValue={(e: any) => e.id}
                          onChange={handleTypeChange}
                          defaultOptions
                          placeholder={t("type")}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "50px",
                            }),
                          }}
                          className={classes.inputFields}
                        />
                      );
                    }}
                      name="subTypeId"
                    />
                  </Grid>
                  {openSubType ? (
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => {
                          const handleChangeSubType = (subType: any) => {
                            setSubType(subType);
                            onChange(subType?.id);
                          };

                          return (
                            <AsyncSelect
                              defaultOptions
                              value={subType}
                              loadOptions={loadOptions}
                              onChange={handleChangeSubType}
                              getOptionLabel={(e: any) => e.name}
                              getOptionValue={(e: any) => e.id}
                              placeholder={t("subtype")}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  height: "50px",
                                  marginLeft: "5%",
                                }),
                              }}
                              className={classes.inputFields}
                            />
                          );
                        }}
                        name="subTypeId"
                      />
                    </Grid>
                  ) : (<></>
                  )}
                </Grid>

                {errors.subTypeId && (
                  <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.subTypeId.message}</Typography>
                )}

                <Controller control={control} render={({ field: { onChange, value, name, ref } }) => {
                  const handleSelectChange = (selectedOption: any) => {
                    setSelectedStateValue(selectedOption);
                    onChange(selectedOption?.id);
                  };

                  return (
                    <div className={classes.dropDownBoxStyle}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        {...register("operationState")}
                        value={selectedStateValue}
                        getOptionLabel={(e: any) => `${t(e.name)}`}
                        getOptionValue={(e: any) => e.id}
                        loadOptions={fetchState}
                        onChange={handleSelectChange}
                        placeholder={t("tankstate")}

                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            lineHeight: "1.4375em",
                            height: "50px",
                          }),
                        }}
                        className={classes.inputFields}
                      />
                    </div>
                  );
                }}
                  name="operationState"
                />
                <Typography align="left" component="p" variant="caption" className={classes.errorStyleSelect}>{errors.operationState?.message}</Typography>
              </Grid>
          }
        </Grid>
        <br />

        {
          subType?.hasTelemetry || false ? (<>
            <Controller control={control} {...register("telemetryEnabled")} render={({ field: { name, onChange, value } }) => (
              <FormControlLabel control={<Checkbox checkedIcon={<CheckBoxIcon htmlColor="rgb(0, 0, 90)" />} checked={value} onChange={(e) => onChange(e.target.checked)} />}  label={t("telementaryenabled")} />
            )}
            />
          </>
          ) : <></>
        }

        {telemetryEnabled && (<>
          <Controller
            control={control}
            {...register("deviceSerialNumber")}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("deviceserialnumber")}
                sx={{
                  "& .MuiOutlinedInput-root": { height: 50 },
                  "& label": { lineHeight: 2.1 },
                }}
                variant="outlined"
                fullWidth
                size="small"
                margin="none"
              />
            )}
          />
          {errors.deviceSerialNumber && (
            <Typography align="left" component="p" variant="caption" className={classes.errorStyle}>{errors.deviceSerialNumber?.message}</Typography>
          )}
        </>
        )}


        <div className={classes.formErr}>
          <div className={classes.formBtn}>
            <Button
              onClick={handleSubmit(onSubmit)}
              style={{
                color: "white",
                backgroundColor: "#00005a",
                width: "20%",
                marginTop: "5%"
              }}
              variant="contained"
              className="btn btn-primary"
              disabled={loading}
            >
              {" "}
              <Typography
                variant="h6"
                sx={{
                  margin: "0px",
                  fontSize: "0.8rem",
                  textTransform: "capitalize",
                }}
              >
                {t("submit")}
              </Typography>{" "}
              {loading && <CircularProgress size={14} />}
            </Button>
          </div>
          {errorMessage &&
            <div className="error" style={{ color: "red", marginTop: "5%" }}>
              <div> {errorMessage}  </div>
            </div>
          }
        </div>
      </form>
    </div>
  );
};

export default Step1;
