import { useEffect, useState } from 'react'
import {useStyle}   from '../../styles/stylemenu'; 
import { Link, useLocation } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
type ListItems = {
  label : string;
  icons: any;
  path: string;
  onClick: any
}

export const MenuItem = ({label, icons, path, onClick}: ListItems) => {
  const { t } = useTranslation();
  const classStyle = useStyle()
  const [active, setActive] = useState(true);
  const location = useLocation();
  const Icon =  icons;

  useEffect(() => {
    setActive(location.pathname === path)
  }, [location, path]);

  return (
    <ListItem component={Link} onClick={onClick} to={path} className={clsx(classStyle.classes.menuItem, active && classStyle.classes.menuItemActive)}>
      <ListItemIcon>
        <Icon className={ clsx(classStyle.classes.menuIcon, active && classStyle.classes.menuIconSelected)}>
          {/* <IconButton key={icons} className={ clsx(classStyle.classes.menuIcon, active && classStyle.classes.menuIconSelected)}> {icons} </IconButton> */}
        </Icon>
      </ListItemIcon>
      <ListItemText primary={t(label)} className={clsx(classStyle.classes.menuText, active && classStyle.classes.menuSelected)} primaryTypographyProps={{variant: "body2"}}></ListItemText>
    </ListItem>
  )
}
