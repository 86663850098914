import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAllEquipments } from "../../../Services/ApiService";
import { RootState } from "../../store";
export interface PageParam{
    pageNumber: number,
    pageSize: number,
    bypassCache: boolean
}

export const getObjects = createAsyncThunk('getObjects', 
    async(arg: PageParam, {getState, dispatch, rejectWithValue}) => {
        try {
            const { objects }: any = getState() as RootState;
            const  object : any = getState() as RootState;
            
            if (object?.objects.data[arg.pageNumber] && arg.bypassCache === true) {
                return {
                  pageNumber: arg.pageNumber,
                  data: object?.objects.data[arg.pageNumber],
                  totalPages: objects.totalPages,
                };
              } else {
                // Fetch data from the API
                const response = await GetAllEquipments(arg.pageSize, arg.pageNumber);
            
                // Dispatch actions to update the store
                return { pageNumber: arg?.pageNumber, data: response?.data, totalPages: response.pagination.totalPages};
            }

          } catch (err: any) {
            if (err.response?.status === 401) {
            //   dispatch(unauthorizedResponseReceived());
            }
            return rejectWithValue(err);
          }
        }
    
)
