import React, { useEffect, useMemo, useState } from "react";
import {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { Controller } from "react-hook-form";
import { useStyle} from "./style";
import Paper from "@mui/material/Paper";
import CloudUpload from "@mui/icons-material/CloudUpload";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from "@mui/material";

type FileProps= {
  control: any;
  name:any;
  onChange:any;
}
const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export const FileInput:React.FunctionComponent<FileProps> = ({ control, name,onChange }) => {
const {classes} = useStyle();
const { t } = useTranslation();
const [files, setFiles] = useState<any[]>([])
const [rejected, setRejected] = useState<any[]>([])

const onDrop = useCallback((acceptedFiles:any, rejectedFiles:any) => {
 
  if (acceptedFiles?.length) {
    setFiles(previousFiles => [
      ...previousFiles,
      ...acceptedFiles.map((file:any) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      )
    ])
  }

  if (rejectedFiles?.length) {
    setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
  }
}, [])

useEffect(() => {
  // Revoke the data uris to avoid memory leaks
  return () => files.forEach(file => URL.revokeObjectURL(file.preview))
}, [files])

const removeFile = (name:any) => {
  setFiles(files => files.filter(file => file.name !== name))
}

const removeAll = () => {
  setFiles([])
  setRejected([])
}

const removeRejected = (name:any) => {
  setRejected(files => files.filter(({ file }) => file.name !== name))
}


const {getRootProps, getInputProps, isDragActive,
  isFocused,
  isDragAccept,
  isDragReject} = useDropzone({
      accept: {
        'image/*': []
      },
      maxSize: 1524 * 1000,
      onDrop
    })

    const style = useMemo(() => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isFocused,
      isDragAccept,
      isDragReject
    ]);
    
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field: { onChange,onBlur } }) => (
        <>
          <div {...getRootProps({style})}>
          <input {...getInputProps()} name={name} onBlur={onBlur} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
          </div>
          <ul>
        {files.map(file => (
            <li key={file.name}>
                
              <img src={file.preview} alt=""  width={100} height={100}  onLoad={() => {
                  URL.revokeObjectURL(file.preview)
                }}/>
<Button onClick={() => removeFile(file.name)} color="error">
<ClearIcon/>
</Button>
          
<p className='mt-2 text-neutral-500 text-[12px] font-medium'>
                {file.name}
              </p>
            </li>
        ))}
    </ul>
     
        </>
      )}
    />
    
  );
};
