import { Box, Fab, Grid, IconButton, Popover, SwipeableDrawer, Typography, useMediaQuery } from '@mui/material'
import { useStyle } from '../../styles/stylemenu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Circle } from '@mui/icons-material';
import { ImageView } from '../ImageCarousel';
import { useEffect, useState } from 'react';
import { EquipmentEdit } from '../EquipementForm/Edit';
import { StandardImageList } from '../EquipementForm/ImageGallery';
import { BorderLinearProgress } from '../Graphs/BorderLinearProgress';
import progressFn from '../../utils/progressFn';
import { SignalWifi3Bar, SignalWifiStatusbar4Bar, SignalWifi0Bar, SignalWifiOff, Battery90, Battery80, Battery60, Battery50, BatteryFull, Battery30, Battery20, Battery1Bar, BatteryAlert } from '@mui/icons-material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { LoaderComponent } from '../LoaderComponent/LoaderComponent';
import { Error } from '../Error/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { TelemetryParam, getTelemetry } from '../../store/features/Telemetry/telemetryAction';
import { clearTemporaryObject } from '../../store/features/Telemetry/telemetrySlice';
import { getDetails } from '../../store/features/Equipment Detail/action';
import { clearDetailObj, setDeletedToFalse } from '../../store/features/Equipment Detail/slice';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getAccess } from '../../Services/ApiService';
import { setNewObjectToFalse } from '../../store/features/Equipment/equipmentSlice';
import { Alert } from '../Alerts/Alert';
import { ToastContainer } from 'react-toastify';
import clsx from 'clsx';
import TestImages from '../EquipementForm/testImages';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
export const EquipmentDetail = (equipmentDetailsInfo: any) => {


    const { t } = useTranslation();
    const { classes } = useStyle();
    const navigate = useNavigate();
    const [anchorSignalEl, setSignalAnchorEl] = useState<HTMLElement | null>(null);


    const [anchorBatteryEl, setBatteryAnchorEl] = useState<HTMLElement | null>(null);
    const matches = useMediaQuery('(max-width:600px)');
    const matchesTab = useMediaQuery('(max-width:768px)');
    const [openImage, setOpenImage] = useState(false);
    const [customerRoles, setCustomerRoles] = useState([]);
    const handleOpen = () => setOpenDrawer(true);
    const handleImage = () => setOpenImage(true);
    const handleCloseEdit = (x: any) => { setOpenDrawer(false) };
    const handleCloseImage = (x: any) => { setOpenImage(false) };
    const dispatch: AppDispatch = useDispatch();
    const objectParam: TelemetryParam = { id: equipmentDetailsInfo.equipmentId };
    const telemetryObject = useSelector((state: RootState) => state.telemetry.temporaryObject);
console.log(telemetryObject)
    const equipmentDetails = useSelector((state: RootState) => state.details.detailObj);
   
    const isLoading = useSelector((state: RootState) => state.details.loading);
    const isError = useSelector((state: RootState) => state.details.error);
    const isErrorTelemetry = useSelector((state: RootState) => state.telemetry.error);
    const isDeletedObj = useSelector((state: RootState) => state.details.deleted);
    const updatedObject = useSelector((state: RootState) => state.details.updated);
    const [openDrawer, setOpenDrawer] = useState(false);
    const open = Boolean(anchorSignalEl);


    const handleBatteryPopoverClose = () => {
        setBatteryAnchorEl(null);
    };

    const handleSignalPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setSignalAnchorEl(event.currentTarget);
    };

    const handleSignalPopoverClose = () => {
        setSignalAnchorEl(null);
    };

    const handleBatteryPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setBatteryAnchorEl(event.currentTarget);
    };
    const openBattery = Boolean(anchorBatteryEl);
    const noDetailsDataFound = (status: any) => {
        if ((isError && isErrorTelemetry) || isDeletedObj) {
            if (status) { dispatch(setDeletedToFalse(equipmentDetailsInfo.equipmentId)) }
            dispatch(clearTemporaryObject());
            dispatch(clearDetailObj());
            navigate('/equipment');
        }

        if ((isError && telemetryObject === undefined) || (Object.keys(telemetryObject).length < 0 && isError)) {
            dispatch(clearTemporaryObject());
            dispatch(clearDetailObj());
            navigate('/equipment');
        }
    }
    const HoverBatteryComponent = (val: any) => {
        return <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none' }}
            open={openBattery}
            anchorEl={anchorBatteryEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleBatteryPopoverClose}
            disableRestoreFocus >
            <Typography sx={{ p: 1 }}>{val?.val} </Typography>
        </Popover>
    }
    const HoverComponent = (val: any) => {
        return <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none' }}
            open={open}
            anchorEl={anchorSignalEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={handleSignalPopoverClose}
            disableRestoreFocus >
            <Typography sx={{ p: 1 }}>{val?.val} </Typography>
        </Popover>
    }
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 13,
        },
    }));
    const BLinearProgress = BorderLinearProgress();

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpenDrawer(false);
        setOpenImage(false)
    };

    const BackButtonBtn = () => {
        navigate('/equipment', { state: 'Back List' });
        dispatch(clearTemporaryObject());
        dispatch(clearDetailObj());
        dispatch(setNewObjectToFalse(equipmentDetailsInfo.equipmentId));
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getTelemetry(objectParam));
                await dispatch(getDetails(objectParam))
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const appRoles = async () => {

        const access = await getAccess();

        const customerAccess = access.customers.map((c: any) => ({ id: c.id, roles: c.roles }));

        setCustomerRoles(customerAccess);

    };

    useEffect(() => { appRoles() }, []);

    const canEdit = () => {

        if (!equipmentDetails) {
            return false;
        }

        if (!customerRoles) {
            return false;
        }

        const editedEquipmentCustomerId = equipmentDetails?.customer?.id;

        const customerRole: any = customerRoles?.find((c: any) => c?.id === editedEquipmentCustomerId);
        if (!customerRole) {
            return false;
        }
        const writerRole = customerRole?.roles?.find((r: string) => r?.includes("writer"))

        return !!writerRole;
    };

    if ((isLoading || Object.keys(equipmentDetails).length === 0) && !isError && !isErrorTelemetry) {
        return (
            <Grid item pt={5} display={'flex'} justifyContent={'center'} flexDirection={'row'} height={'100%'}>
                <LoaderComponent />
            </Grid>
        )
    }

    return (
        <div className={matches ? classes.detailCardMobile : classes.detailItemCard}>
            {(isError || isErrorTelemetry) || isDeletedObj ? <Error isOpen={true} message={isError || isErrorTelemetry} noDetailsDataFound={noDetailsDataFound} details={true} deletedObj={isDeletedObj} /> : ''}
            {updatedObject === equipmentDetails.objectId ? <Alert message={t("thisinformationhasbeenupdated")} updated={updatedObject} id={updatedObject} state={true} /> : ''}
            <Grid container>
                {!isError &&
                    <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: { xs: 'row', md: 'row' }, width: '100%', marginTop: { xs: '10px', md: '30px' } }} mt={3.5} mb={2.5} container>
                        <Grid item xs={12} md={12} sx={{ display: "flex", flexDirection: { xs: 'row', md: 'row' }, width: '100%', marginTop: { xs: '10px', md: '30px' } }} mt={3.5} mb={2.5} container>
                            <Grid item sx={{ display: equipmentDetailsInfo.true ? "none" : "flex", justifyContent: "space-between", flexDirection: { xs: 'column-reverse', md: 'row' }, width: '100%' }}>
                                <Grid item xs={12} md={6}>
                                    <Grid item display={"flex"} justifyContent="start">
                                        <Typography variant="h5" sx={{ fontSize: { xs: "25px", md: "25px" }, paddingLeft: "7px", textTransform: "capitalize", fontWeight: "bold" }}>{equipmentDetails?.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} mb={2} md={6}>
                                    <Grid item container sx={{ display: "flex", flexDirection: { xs: 'row', md: 'row' }, justifyContent: { md: "space-between", xs: 'space-between' }, width: '100%' }} >
                                        <Grid item pr={"10px"} display={"flex"} justifyContent={"start"}>
                                            <button onClick={() => BackButtonBtn()} className={classes.arrowBack}>
                                                <ArrowBackIcon className={classes.backArrowStyle} />
                                            </button>
                                        </Grid>
                                        {
                                            canEdit() ? <Grid item sx={{ display: "flex", flexDirection: { xs: 'row', md: 'row' } }}>
                                                <Grid item >
                                                    <button onClick={handleOpen} className={classes.arrowBack}>
                                                        <Typography variant="h6" sx={{ padding: { md: "0px 20px 0px 20px", xs: "0px 16px 0px 15px" }, background: "#00005a", color: "#ffff", borderRadius: "50px", fontSize: { xs: "1rem", md: "1rem" } }}>{t("edit")}</Typography>
                                                    </button>

                                                    <SwipeableDrawer
                                                        anchor={'right'}
                                                        open={openDrawer}
                                                        onClose={toggleDrawer}
                                                        onOpen={toggleDrawer}
                                                        className={classes.drawerForm}
                                                        classes={{
                                                            paper: clsx(
                                                                (!matches && !matchesTab) ? classes.drawerForm : ((matchesTab && !matches) ? classes.drawerFormTablet : classes.drawerFormMobile)
                                                            ),
                                                        }}
                                                    >
                                                        <EquipmentEdit equipDetails={equipmentDetails} handleModalClose={handleCloseEdit} />

                                                    </SwipeableDrawer>
                                                </Grid>
                                            </Grid> : <></>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item pr={{ xs: 0, md: 0 }} xs={12} md={equipmentDetailsInfo.true ? 12 : 6}>

                            <Grid sx={{ display: 'flex', width: '100%', justifyContent: { md: "center", xs: 'center' }, paddingBottom: "24px", position: "relative " }}>
                                <ImageView equipment={equipmentDetails} />
                                {

                                    canEdit() ?
                                        <Fab style={{ backgroundColor: "#00005a", color: "#ffff", width: "30px", height: "30px", position: 'absolute', top: '0', right: '16px' }} aria-label="edit" onClick={handleImage}>
                                            <ModeEditOutlineIcon />
                                        </Fab>
                                        :
                                        <></>
                                }

                                <SwipeableDrawer
                                    anchor={'right'}
                                    open={openImage}
                                    ModalProps={{ keepMounted: false }}
                                    onClose={toggleDrawer}
                                    onOpen={toggleDrawer}
                                    className={classes.drawerForm}
                                    classes={{ paper: clsx(!matches ? classes.drawerForm : classes.drawerFormMobile) }}>

                                    <StandardImageList equipDetails={equipmentDetails} handleModalClose={handleCloseImage} />

                                </SwipeableDrawer>
                            </Grid>

                            <Grid p={1} display={{ md: 'none', xs: 'flex' }}>
                                <div className={classes.equipmentInfoDetails}>
                                    <div className={classes.descriptionDetailPage}>
                                        <Typography sx={{ fontSize: { xs: "25px", md: "25px" }, fontWeight: "500", color: "00000" }} component="h5"> {t("description")} </Typography>
                                        <Typography sx={{ fontSize: { xs: "20px", md: "20px" }, fontWeight: "400", color: "#575757" }} component="h6"> {equipmentDetails?.description} </Typography>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={equipmentDetailsInfo.true ? 12 : 6}>
                            <div className={matches ? classes.detailItemCardMobile : classes.detailItemListCard}>
                                <Grid item container sx={{ padding: { xs: '10px', md: '20px' } }}>
                                    <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                        <Grid xs={12} md={6} item>
                                            <Typography className={classes.detailTitle} component="h6"> {t("tag/badge")} </Typography>
                                        </Grid>
                                        <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                            <Grid display={"flex"} justifyContent={"center"} item>
                                                <Typography className={classes.detailDescription} variant="h6"> {equipmentDetails?.idTag} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                        <Grid xs={12} md={6} item>
                                            <Typography className={classes.detailTitle} component="h6">{t("status")}  </Typography>
                                        </Grid>
                                        <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                            <Grid display={"flex"} justifyContent={"center"} item>
                                                <Typography className={classes.detailDescription} variant="h6"> {`${t(equipmentDetails?.operationStateString)} `} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                        <Grid xs={12} md={6} item>
                                            <Typography className={classes.detailTitle} component="h6">{t("type")}</Typography>
                                        </Grid>
                                        <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                            <Grid display={"flex"} justifyContent={"center"} item>
                                                <Typography className={classes.detailDescription} variant="h6"> {`${t(equipmentDetails?.type?.name)} `} </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {
                                        equipmentDetails?.subType ?
                                            <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                                <Grid xs={12} md={6} item>
                                                    <Typography className={classes.detailTitle} component="h6">{t("subtype")} </Typography>
                                                </Grid>
                                                <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                                    <Grid display={"flex"} justifyContent={"center"} item>
                                                        <Typography className={classes.detailDescription} variant="h6"> {equipmentDetails?.subType?.name} </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid> : (<></>)
                                    }

                                    {
                                        equipmentDetails?.vendor ?
                                            <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                                <Grid xs={12} md={6} item>
                                                    <Typography className={classes.detailTitle} component="h6">{t("vendorname")} </Typography>
                                                </Grid>
                                                <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                                    <Grid display={"flex"} justifyContent={"center"} item>
                                                        <Typography className={classes.detailDescription} variant="h6"> {equipmentDetails?.vendor?.name} </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            (<></>)
                                    }

                                    {
                                        equipmentDetails?.customer ?
                                            <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                                <Grid xs={12} md={6} item>
                                                    <Typography className={classes.detailTitle} component="h6">{t("customername")} </Typography>
                                                </Grid>
                                                <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                                    <Grid display={"flex"} justifyContent={"center"} item>
                                                        <Typography className={classes.detailDescription} variant="h6"> {equipmentDetails?.customer?.name} </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            (<></>)
                                    }

                                    {
                                        equipmentDetails?.assignedLocation ?
                                            <Grid className={classes.detailKeyValueCard} item display={"flex"} md={12}>
                                                <Grid xs={12} md={6} item>
                                                    <Typography className={classes.detailTitle} component="h6"> {t("location")} </Typography>
                                                </Grid>
                                                <Grid display={"flex"} flexDirection={"column"} xs={12} md={6} item>
                                                    <Grid display={"flex"} justifyContent={"center"} item>
                                                        <Typography className={classes.detailDescription} variant="h6"> {equipmentDetails?.assignedLocation?.name} </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            : (<></>)
                                    }

                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} display={{ md: 'flex', xs: 'none' }}>
                            <div className={classes.equipmentInfoDetails}>
                                <div className={classes.descriptionDetailPage}>
                                    <Typography sx={{ fontSize: { xs: "25px", md: "25px" }, fontWeight: "500", color: "00000" }} component="h5"> {t("description")} </Typography>
                                    <Typography sx={{ fontSize: { xs: "20px", md: "20px" }, fontWeight: "400", color: "#575757" }} component="h6"> {equipmentDetails?.description} </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                }
                <Grid mt={4} md={12} item>
                    <hr />
                </Grid>
                {
                    telemetryObject?.operationStateString !== 'Decommissioned' && telemetryObject && !isErrorTelemetry &&
                    <Grid display={'contents'} md={12} item>
                        <Grid ml={{ xs: '15px' }} mr={{ xs: '15px' }} md={12} xs={12} lg={12} item display={"flex"} justifyContent={"space-between"}>
                            <Typography sx={{ fontSize: { xs: "25px", md: "25px" }, fontWeight: "500", color: "00000" }} component="h5">{t("telemetryinformationdisplay")} </Typography>

                            <Typography sx={{ fontSize: { xs: "0.9rem", md: "1rem" }, fontWeight: "500", color: telemetryObject?.connectionState === 'Disconnected' ? "red" : "green" }}>{telemetryObject?.connectionState === 'Disconnected' ? t("disconnected") : t("connected")} </Typography>
                        </Grid>
                        <Grid sx={{ paddingRight: { md: '24px' }, paddingBottom: { md: '20px', xs: '20px', lg: '30px' } }} item xs={12} md={equipmentDetailsInfo.true ? 12 : 7}>
                            <div className={matches ? classes.detailItemCardMobile : classes.detailItemListCard}>
                                <Grid container sx={{ padding: { md: "20px", xs: "7px" }, marginTop: { md: "20px" } }}>
                                    <Grid item display={"flex"} justifyContent={"center"} container>
                                        <Grid container>
                                            <Grid item display={"flex"} justifyContent={"end"} width={'100%'}>
                                                <Circle sx={{ color: 'red', width: { xs: "15px", md: '10px' }, height: { xs: "15px" }, paddingLeft: "10px", paddingTop: { xs: "3px" } }} />
                                                <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, fontWeight: "bold", color: "#00000" }} component="h6">Alarm </Typography>

                                                <Circle sx={{ color: 'green', width: { xs: "15px", md: '10px' }, height: { xs: "15px" }, paddingLeft: "10px", paddingTop: { xs: "3px" } }} />
                                                <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, fontWeight: "bold", color: "#00000" }} component="h6">Ok </Typography>

                                            </Grid>
                                        </Grid>
                                        {
                                            telemetryObject?.sensors?.map((resp: any, index: any) => (
                                                <Grid item xs={12} md={12} pb={3} key={index}>
                                                    <Grid display={"flex"} pb={0}>
                                                        <Typography sx={{ fontSize: { xs: "20px", md: "20px" }, fontWeight: "500", color: "#00000" }} component="h6"> {t(resp?.name)}
                                                            <Typography>
                                                                {Math.trunc(resp?.value)}
                                                                {resp?.engineeringUnit}
                                                                {<LightTooltip title={resp?.lastUpdateTime} >
                                                                    <IconButton>
                                                                        <InfoOutlinedIcon style={{ width: "15px", height: "15px" }} />
                                                                    </IconButton>
                                                                </LightTooltip>}
                                                            </Typography>

                                                        </Typography>
                                                    </Grid>
                                                    <Grid display={"flex"} justifyContent={"center"}>
                                                        <Box sx={{ width: '100%', color: '#7f' }}>
                                                            <BLinearProgress color={(resp?.value <= resp?.lowLimit) || (resp?.value >= resp?.highLimit) ? 'error' : 'success'} variant="determinate" value={progressFn(resp)} sx={{ height: "6px" }} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid container display={"flex"} flexDirection={'row'} justifyContent={'space-between'} pt={0.5}>
                                                        <Grid display={'flex'} flexDirection={'row'} item>
                                                            <Typography sx={{ fontSize: { xs: "15px", md: "15px" }, fontWeight: "normal", color: "#00000" }} component="h6">{t("rangeMin")}  {resp?.rangeMin} {resp?.engineeringUnit}</Typography>
                                                        </Grid>
                                                        <Grid display={'flex'} flexDirection={'row'} item>
                                                            <Typography sx={{ fontSize: { xs: "15px", md: "15px" }, fontWeight: "normal", color: "#00000" }} component="h6">{t("rangeMax")}  {resp?.rangeMax} {resp?.engineeringUnit}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid sx={{ paddingRight: { md: '24px' }, paddingBottom: { md: '20px', xs: '20px', lg: '30px' } }} item xs={12} md={equipmentDetailsInfo.true ? 12 : 5}>
                            <div className={matches ? classes.detailItemCardMobile : classes.detailItemListCard}>
                                <Grid container sx={{ padding: { md: "20px", xs: "7px" }, marginTop: { md: "20px" } }}>
                                    <Grid item display={"contents"} container>
                                        <Grid container>
                                            <Grid item pb={3} display={"flex"} justifyContent={"end"} width={'100%'}>
                                                <Circle sx={{ color: 'red', width: { xs: "15px", md: '10px' }, height: { xs: "15px" }, paddingLeft: "10px", paddingTop: { xs: "3px" } }} />
                                                <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, fontWeight: "bold", color: "#00000" }} component="h6">{t("alarm")} </Typography>

                                                <Circle sx={{ color: 'green', width: { xs: "15px", md: '10px' }, height: { xs: "15px" }, paddingLeft: "10px", paddingTop: { xs: "3px" } }} />
                                                <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, fontWeight: "bold", color: "#00000" }} component="h6">{t("ok")} </Typography>

                                                <Circle sx={{ color: 'grey', width: { xs: "15px", md: '10px' }, height: { xs: "15px" }, paddingLeft: "10px", paddingTop: { xs: "3px" } }} />
                                                <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, fontWeight: "bold", color: "#00000" }} component="h6">{t("offline")} </Typography>

                                            </Grid>
                                        </Grid>
                                        {
                                            telemetryObject?.battery && (

                                                <Grid item xs={12} md={12} pb={3}>
                                                    <Grid display={"flex"} justifyContent={"space-between"}>

                                                        <Typography sx={{ fontSize: { xs: "20px", md: "20px" }, fontWeight: "500", color: "#00000" }} component="h6"> {t(telemetryObject?.battery?.name)}
                                                            <Typography >{Math.floor(telemetryObject?.battery?.value)} {telemetryObject?.battery?.engineeringUnit}
                                                                {<LightTooltip title={telemetryObject?.battery?.lastUpdateTime} >
                                                                    <IconButton>
                                                                        <InfoOutlinedIcon style={{ width: "15px", height: "15px" }} />
                                                                    </IconButton>
                                                                </LightTooltip>}
                                                            </Typography>


                                                        </Typography>



                                                        {telemetryObject?.connectionState === "Connected" ? (
                                                            <Grid item display={"flex"} justifyContent={"end"}>
                                                                {telemetryObject?.battery?.value === 100 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <BatteryFull style={{ color: "green" }} className={classes.notificationIcon} />
                                                                    </Grid>)
                                                                }
                                                                {telemetryObject?.battery?.value >= 90 && telemetryObject?.battery?.value < 100 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery90 style={{ color: "green" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 80 && telemetryObject?.battery?.value < 90 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery80 style={{ color: "green" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 60 && telemetryObject?.battery?.value < 80 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery60 style={{ color: "green" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 50 && telemetryObject?.battery?.value < 60 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery50 style={{ color: "green" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 30 && telemetryObject?.battery?.value < 50 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery30 style={{ color: "orange" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 20 && telemetryObject?.battery?.value < 30 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery20 style={{ color: "red" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 10 && telemetryObject?.battery?.value < 20 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery1Bar style={{ color: "red" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery?.value >= 0 && telemetryObject?.battery?.value < 10 && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <BatteryAlert style={{ color: "red" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                                {telemetryObject?.battery === null && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <BatteryAlert style={{ color: "red" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        ) : (
                                                            <Grid item display={"flex"} justifyContent={"end"}>
                                                                {telemetryObject?.battery?.value && (
                                                                    <Grid item display={"flex"} justifyContent={"end"}>
                                                                        <Battery30 style={{ color: "#575757" }} className={classes.notificationIcon} />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        )}

                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        {
                                            telemetryObject?.signal && (
                                                <Grid item md={12} xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography sx={{ fontSize: { xs: "20px", md: "20px" }, fontWeight: "500", color: "#00000" }} component="h6"> {t(telemetryObject?.signal?.name)}
                                                        <Typography>{Math.floor(telemetryObject?.signal?.value)} {telemetryObject?.signal?.engineeringUnit}
                                                            {<LightTooltip title={telemetryObject?.signal?.lastUpdateTime} >
                                                                <IconButton>
                                                                    <InfoOutlinedIcon style={{ width: "15px", height: "15px" }} />
                                                                </IconButton>
                                                            </LightTooltip>}</Typography>






                                                    </Typography>
                                                    {telemetryObject?.connectionState === "Connected" ? (
                                                        <Grid item display={"flex"} justifyContent={"end"}>
                                                            {telemetryObject?.signal?.value >= 70 && (
                                                                <Grid>
                                                                    <SignalWifiStatusbar4Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                                                </Grid>
                                                            )}
                                                            {telemetryObject?.signal?.value >= 50 && telemetryObject?.signal?.value < 70 && (
                                                                <Grid>
                                                                    <SignalWifi3Bar style={{ color: "green" }} className={classes.notificationIcon} />
                                                                </Grid>
                                                            )}
                                                            {telemetryObject?.signal?.value >= 10 && telemetryObject?.signal?.value < 50 && (
                                                                <Grid>
                                                                    <SignalWifi0Bar style={{ color: "red" }} className={classes.notificationIcon} />
                                                                </Grid>
                                                            )}
                                                            {telemetryObject?.signal?.value < 10 && (
                                                                <Grid>
                                                                    <SignalWifiOff className={classes.notificationIcon} />
                                                                </Grid>
                                                            )}
                                                            {telemetryObject?.signal === null && (
                                                                <Grid>
                                                                    <SignalWifiOff className={classes.notificationIcon} />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    ) : (
                                                        <Grid item display={"flex"} justifyContent={"end"}>
                                                            <Grid>
                                                                <SignalWifiOff style={{ color: "#575757" }} className={classes.notificationIcon} />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <ToastContainer hideProgressBar={true} />
        </div>
    )
}