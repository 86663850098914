import { Grid } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom'
import { EquipmentDetail } from '../components/EquipmentDetail/EquipmentDetail';

export const EquipmentDetails = () => {
    const l = useLocation();
  return (
    <Grid>
      <EquipmentDetail sx={{height:'100%'}} equipmentId={l.state}/>
    </Grid>
    
  )
}
