import React from 'react';
import ReactDOM from 'react-dom/client';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
// import { store } from './Redux/store'
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./i18n/i18n";
//MSAL Imports
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);



root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
       
        
        <App/>
    
      </MsalProvider>
    </Provider>
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
