import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { MainContainer } from "./utils/maincontainer";
import { FileInput } from "./utils/FileInput";
import { PrimaryButton } from "./utils/primarybutton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { Form } from "./utils/form";
import axios from "axios";
import { Alert, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Input, InputLabel } from "@mui/material";
import { useStyle } from "../../styles/styles";
import { postImageData } from "../../Services/ApiService";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
type UserSubmitForm = {
  image: File;
  imgDefault: File;
  onChange: any;
  isDefault: boolean,
};

 type ImageObj = {
    isDefault: boolean,
    image: any
  }
  
export const Step2 = ({ state, setOpenModal }: any) => {
  const validationSchema = Yup.object().shape({
    isDefault: Yup.mixed().required("Default Image is required"),
    image: Yup.mixed().required("Images are required"),

  });

  const { register, handleSubmit, reset, control, getValues, watch, formState: { errors },} = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  });
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const { classes } = useStyle();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false)
   };
   const handleCancel = () => {
  
    setOpen(true);
  };


  useEffect(() => {
    setLoading(false);
  }, []);
  // Functions to preview multiple images
  
  const onSubmit = (data: UserSubmitForm) => {
    setLoading(true);
    const imagesArray: ImageObj[] = [];

    if(data.image){
      Object.entries(data.image).map((res: any) => {
        const imgData: ImageObj = {
          isDefault: false,
          image: res[1]
        }
       return imagesArray.push(imgData);
      });
    } 
    Object.entries(data.imgDefault).map(res => {
      const imgData: ImageObj = {
        isDefault: true,
        image: res[1]
      }
      return imagesArray.push(imgData);
    });

    imagesArray.map(async (res: any) => {
      const reqData = { ...res, id:state.equipmentId };
     try {
        const res_1 = await postImageData(reqData);
        setErrorMessage("Image Uploaded Successfully");
        setTimeout(() => {
          setLoading(false);
        }, 500);
        navigate('/equipment');
        setOpenModal(false)
       return
      } catch (res_2: any) {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    });
  };

  return (
 
    <MainContainer>

      <h5 className={classes.title}>{t("uploadimage")} </h5>
      
      <Form onSubmit={handleSubmit(onSubmit)}>
    
        <Grid>
          
          <FileInput control={control} {...register("image")}/>
        </Grid>
        {errors.image && <p style={{ color: 'red' }}> {errors.image.message}</p>}
        
        
        <Button type="submit" variant="contained"  style={{
              margin: "10px 15px 0px 0px",
              color: "white",
              backgroundColor: "#00005a",
            }} className="btn btn-primary" disabled={loading}> {t("submit")}  {loading && (
              <CircularProgress size={14} />
            )} </Button>
        {errorMessage && <div className="error" style={{color:"green",marginTop:"10%"}}> {errorMessage} </div>}
      </Form>
    </MainContainer>
  );
};

