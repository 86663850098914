import React from 'react'

export function DisconnectedStatePin() {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.79 354.5" width='70px' height='70px'>
  <path d="M85.75,0C38.37,0,0,37.4,0,83.5c0,17.6,5.62,33.9,15.11,47.4l70.15,156.1c23.87-52.83,47.73-105.67,71.6-158.5,6.3-9.25,14.87-25.16,14.63-45C170.98,40.97,130.19,0,85.75,0Z" fill= "#ffc62a"/>
  <circle cx="88.5" cy="90.5" r="52.2" fill="#fff"/>
  <path d="M146.89,260.5l46.78-55.87c.66-.79,.81-1.89,.38-2.82l-18.86-41.42c-.43-.94-1.36-1.55-2.4-1.56l-58.41-.71c-1.1-.01-2.09,.65-2.51,1.66l-18.12,44.41c-.39,.95-.2,2.04,.5,2.81,17.55,17.84,35.09,35.67,52.64,53.51Z" fill= "#ee2600" stroke="#fff" strokeMiterlimit="10" strokeWidth="3px"/>
  <rect x="140.7" y="174.16" width="6.45" height="29" fill="#fff" stroke="#fff" strokeMiterlimit= "10"/>
  <circle cx="143.92" cy="225.06" r="5.63" fill="#fff" stroke ="#fff" strokeMiterlimit="10" />
</svg>

  )
}

const fillPercentage = (percent:number) => `${percent}%`;

const markerOutlineColor = (hasAlarms: boolean|undefined) => {

    return hasAlarms ? "red" : "green";
   
   
      
    }

export function AlarmAwareStatePin(props: {percent: number, hasAlarms: boolean}) {
    return (<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 287" width='50px' height='50px'>
      <path d="M88.5,0C39.62,0,0,37.38,0,83.5c0,17.59,5.77,33.91,15.62,47.38l72.38,156.12,70.87-152.86c11.37-14.05,18.13-31.6,18.13-50.64C177,37.38,137.38,0,88.5,0Z" fill={markerOutlineColor(props.hasAlarms)}/>
      <circle cx="88.5" cy="90.5" r="72" fill=" #fff" stroke="#fff" strokeMiterlimit= "10" strokeWidth=" .25px"/>
      <text fill="#000000" fontSize="55"  fontWeight="550" x="26" y="105">{fillPercentage(props.percent)}</text>
    </svg>
    );
}