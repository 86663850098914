import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Home from "./pages/Home";
import { PageLayout } from "./components/LandingPage";
import { QueryClient, QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';

function App() { 
    const client = new QueryClient();

    return(
      <div style={{overflow:'hidden'}} className="App">
            <AuthenticatedTemplate>
                <QueryClientProvider client={client}>
                        <Home/> 
                   <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
                </QueryClientProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <PageLayout/>
            </UnauthenticatedTemplate>
        </div>
    )
}
export default App