import { Box, Card, CardMedia, CircularProgress, Grid, ImageListItem, ImageListItemBar } from '@mui/material';
import { Pagination, Lazy, Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStyle } from '../../styles/stylemenu';
import noImg from '../../assets/noImg.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const ImageView = ({equipment }: any) => {
    const { classes } = useStyle();
    const largeImages = equipment?.images?.filter((image: any) => image.sizeString === 'Large');
    const finalImages = largeImages?.sort((a:any, b:any) => a?.sortOrder - b?.sortOrder);

    if(equipment?.images?.length < 1 || equipment?.images === undefined){ 
        return (
            <Card sx={{maxWidth:650}}>
                <CardMedia
                    component="img"
                    sx={{ height: { md:'400px', xs: "250px", objectFit:'scale-down'}}}
                    src={noImg}
                    alt={noImg}
                    className={classes.equipmentListImage}
                />
            </Card>
            
        )
    }

  return ( 
    equipment.images ?
            <Card sx={{maxWidth:650}}>
                <ImageListItem sx={{display:'block'}}>
                    <ImageListItemBar 
                        sx={{ background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.45)0%, rgba(0, 0, 0, 0.25)55%, rgba(0, 0, 0, 0.5)100%)', height:'10%', zIndex:2 }}
                        position='bottom'/>
                        <Swiper
                            modules={[Pagination, Navigation, Lazy]}
                            lazy
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={true}
                            pagination={{
                                clickable: true,
                                bulletClass: `swiper-pagination-bullet ${classes.swipperClass}`,
                                bulletActiveClass: `swiper-pagination-bullet-active ${classes.swipperPagination}`,
                                }}
                            scrollbar={{ draggable: true }}
                            onSlideChange={() => {}}>
                            { 
                                finalImages.map((uri: any, index: any) => ( 
                                        <SwiperSlide key={index}>
                                            <Box component='img' src={uri.imageUrl} alt='Reload Images' sx={{height: {xs:310, md:400}, display: 'block', overflow:'hidden', width:'100%', objectFit:'scale-down'}}/>
                                        </SwiperSlide>
                                ))
                            }
                        </Swiper>
                
                </ImageListItem>
            </Card>
            :
            <Card sx={{maxWidth:500}}>
                <Grid item >
                    <CircularProgress />
                    <h5>Please Wait while we fetch images</h5>
                </Grid>
            </Card>

  )
}
