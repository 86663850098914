import styled from "@emotion/styled";
import React from "react";

const ButtonWrapper = styled.div<any>`
  border: none;
  outline: none;
  color: #fff;
  padding: 6px 1em;
  font-size: ${({ size }: any) => (size ? size + "px" : "18px")};
  font-weight: 600;
  border-radius: 3px;
  background-color: #2a9d8f;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: #21867a;
  }
  &:focus {
    outline: none;
  }
`;

export function Button(props : any) {
  const { size } = props;

  return (
    <ButtonWrapper size = {size } className={props.className}>
      {props.children}
    </ButtonWrapper>
  );
}