import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { GetEquipmentDetail } from "../../../Services/ApiService";
import { unauthorizedResponse } from "./slice";
export interface IDetailPage{
    id: string
}

export const getDetails = createAsyncThunk('getObjectDetails', 
    async(arg: IDetailPage, {getState, dispatch, rejectWithValue}) => {
        const currentState = getState() as RootState;
        try{
            const response = await GetEquipmentDetail(arg.id);
            return response;
        }catch(err: any){
            if(err.response.status === 401){
                dispatch(unauthorizedResponse())
            }
            return rejectWithValue(err)
        }
    }
)
