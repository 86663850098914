import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { msalInstance } from '../../authConfig';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { setDeletedToFalse } from '../../store/features/Equipment Detail/slice';
import { useTranslation } from 'react-i18next';
export const Error = ({isOpen, message, noDetailsDataFound, details, deletedObj} : any) => {

  const [open, setOpen] = useState(isOpen);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const handleLogout = () => { 
    setOpen(false);     
    msalInstance.logoutRedirect();
  }
  const handleClose = () => { 
    setOpen(false);     
    noDetailsDataFound(deletedObj ? true : '');
    if(!details){
      window.location.reload();
    }
  }

  const handleCloseForbiden = () => {
    setOpen(false);     
    noDetailsDataFound(deletedObj ? true : '');
  }

  const handleDeleted = () => {
    setOpen(false);     
    dispatch(setDeletedToFalse(false));
    noDetailsDataFound(true);
    
  }

  return (
    <div>
      <Dialog
        open= {open}
        onClose={ message?.response?.status === 401 ? handleLogout : handleClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {deletedObj ? 
              <DialogTitle id="alert-dialog-title">
                {"STATUS UPDATE" }
              </DialogTitle> :
              <DialogTitle id="alert-dialog-title">
                {message?.response?.status === 401 ? t('sessionupdate') : t('failurenotice') }
              </DialogTitle> 
              }
         
          <DialogContent>
          { deletedObj ? 
            <DialogContentText id="alert-dialog-description">
              {t('thisinformationisnolongeravailable')}
            </DialogContentText> 
         :
          <DialogContentText id="alert-dialog-description">
            {message && message?.response?.status === 401 ? t('yoursessionhasexpired') : message?.response?.status === 403 ? t('theoperationisnotallowed') : message?.response?.status === 400 ? t('operationfailed') :t('anerroroccured')  }
              <br></br>
            {message && message?.response?.status === 401 ? '' : message?.response?.status === 403 ? t('duetoinsufficientrights') : t('pleasetryagainlater')}
          </DialogContentText>
          }
         
        </DialogContent>

        {
          deletedObj ? 
            <DialogActions>
              <Button onClick={handleDeleted} autoFocus> OK</Button>
            </DialogActions>
                : 
            <DialogActions>
            { message?.response?.status === 401 ?  <Button onClick={handleLogout} autoFocus> {t('logout')} </Button> : message?.response?.status === 403 ? <Button onClick={handleCloseForbiden}>OK</Button> :<Button onClick={handleClose}>OK</Button> }
            </DialogActions>
        }
        
      </Dialog>
    </div>
  )
}