import React from 'react';
import { useDrop, useDrag } from 'react-dnd';
// import styles from '../styles/Card.module.css';
import styles from "./utils/Card.module.css"

function Card({  item ,title, moveImage }:any) {
  const ref = React.useRef<HTMLInputElement>(null);
 
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'image',
    item: { item },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'image',
      collect: (monitor) => ({
        
        isOver: monitor.isOver()
      }),
     
      drop: (targetItem: any) => {
       
        console.log(targetItem);
        console.log(item);
        moveImage(item, targetItem.item);
        //item.index = index;
      }
    }),
    [item]
  );

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  if(!item) {
    return (<span>Error!</span>);
  }

  return (
    <div className={styles.draggable} ref={ref} style={{ opacity }} >
      <img src={item.imageUrl}  className={styles.draggable}  alt={title} style={{ height: "120px", width: "120px" }} />
    </div>
  );
}

export default Card;