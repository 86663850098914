import React from 'react'
import { useLocation } from 'react-router-dom'
import { EquipmentEdit } from '../components/EquipementForm/Edit';


export const EquipmentEdits = () => {
    const l = useLocation();
  return (
    <EquipmentEdit equipDetails={l.state}/>
  )
}