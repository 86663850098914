import { useMsal } from "@azure/msal-react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { tokenRequest } from "../../authConfig";

export const SignInButton = (name: any) => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = async (loginType: string) => {
        setAnchorEl(null);

        if (loginType === "popup") {
            await instance.loginPopup(tokenRequest);
        } else if (loginType === "redirect") {
            await instance.loginRedirect(tokenRequest);
        }
    }

    return (
        <div>
            <Button
                onClick={() => handleLogin("redirect")}
                color="inherit"
            >
                {name.name}
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogin("redirect")} key="loginRedirect">Sign in using Redirect</MenuItem>
            </Menu>
        </div>
    )
}