import { createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { getTelemetryInformation } from "../../../Services/ApiService";
import { unauthorizedResponse } from "./telemetrySlice";

export interface TelemetryParam{
    id: string,
}


export const selectIndependentObjectById = (objectId: string) =>
  createSelector(
    (state: RootState) => state,
    (data: any) => data.telemetry.data.find((obj: any) => obj.objectId === objectId)
  );

  
export const getTelemetry = createAsyncThunk('getTelemetry', 
    async(arg: TelemetryParam, {getState, dispatch, rejectWithValue}) => { 
        try{
            const response = await getTelemetryInformation(arg.id);
            return response.telemetry;
        }catch(err: any){ console.log(err)
            if(err.response.status === 401){
                dispatch(unauthorizedResponse())
            }
            return rejectWithValue(err)
        }
    }
)

