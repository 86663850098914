
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui"; 
const theme = createTheme();

export const useStyle = makeStyles()({
    mainRoot:{
        overflow:"hidden",
        // height:"100vh"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
    },
    menuItem : {
        width: "80%",
        borderRadius: theme.spacing(1),
    },
    menuItemActive: {
        backgroundColor: "#EBEBEC !important"
    },
    menuSelected: {
        color: "black"
    },
    menuIcon: {
        color: "white",
        padding: "0px"
    },
    menuIconSelected: {
        color: "black",
        padding: "0px",
    },
    menuText : {
        color: "white"
    },
    appBar :{
        background: "#6EC17",
    },
    appBarSpacer: {
    },
    navigationDrawer : {
        width: "220px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        height: "100vh",
        backgroundColor: "#00005A"
    },
    navigationDrawerCollapse: {
        "width" : theme.spacing(9)
    },
    navigationToolbar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: theme.spacing(1),
        // ...theme.mixins.toolbar
    },
    navigationToolbarCollapse:{
        justifyContent: "center",
        paddingRight: "0px",
    },
    navigationLogo: {
        "width" : "85%",
    },  
    navigationLogoMobile: {
        "width" : "85%",
        "cursor" : "pointer",
    },
    navigationLogoContainer:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10%",
        marginTop: "10%"
    },
    navigationSpacer: {
        flex: "1"
    },
    navigationList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: "1",
        width:"105%"
        
    },
    tooltipContainer:{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "5%"
    },
    
    tooltipValueDiv:{
        margin: "0px",
        fontWeight:"normal !important"
    },
    tooltipKeyDiv:{
        margin:"0px",
        fontSize: ""
    },
    tooltipValue:{
        margin: "0px",
        fontWeight: "normal",
        fontSize: "14px"

    },
    tooltipKey:{
        margin:"0px !important",
        fontSize: "13px"
    },
    tooltipBTN:{
        backgroundColor: "#00005a",
        color: "white",
        border:"none",
        borderRadius:"3px",
        paddingTop:"3px",
        paddingBottom:"3PX"
    },
    tooltipBTNContainer :{
        display: "flex",
        justifyContent: "center"
    },
    popUpTooltip : {
      margin:"20px 15px 15px 20px"  
    },
    popUpTooltipContainer : {
        margin:"20px 15px 15px 20px"  
      },
    dialog: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: "0px",
        width: "50%",
        maxHeight:"100%",
        height:"100%"
    },
    dialogMobile: {
        position: 'absolute',
        right: 0,
        top: 0,
        margin: "0px",
        width: "100%",
        height:"100%"
    },
    equipmentInfoDiv: {
        display:"flex",
        flexDirection:"column"
    },
    equipmentInfoTitle:{
        display:"flex",
        justifyContent:"space-between",
        width:"100%",
    },
    equipmentNameTitle:{
        margin: "0px",
        padding: "0px",
    },
    equipmentInfoImage: {
        display:"flex",
        justifyContent:"center",
        height: "300px",
        width: "100%",
        // margin:"15px"
    },
    equipmentImageContaier:{
        display:"flex",
        justifyContent:"center",
        width:"70%",
        padding:"20px",
        background:"#f6f6f6",
    },
    equipmentImageContaierMobile:{
        display:"flex",
        justifyContent:"center",
        width:"100%",
        padding:"5px",
        background:"#f6f6f6",
    },
    equipmentImage: {
        width:"100%",
        height: "100%",
        borderRadius:"10px",
    },
    equipmentImageMobile: {
        height: "100%",
        borderRadius:"5px",
    },
    equipmentStatusInfo:{
        position: "absolute",
        top: "0%",
        textAlign: "center",
        color: "white",
        backgroundColor: "#3cec44",
        padding:'4px',
        fontSize:'1.3rem',
        fontWeight:'500'
    },
    equipmentInfoDetails:{
        display: "flex",
        justifyContent:"flex-start",
        marginBottom:"15px",
        marginTop:"15px"
    },
    equipmentInfoKey:{
        width: "50%",
        display: "flex",
        flexDirection:"column",
        margin: "10px"
    },
    equipmentInfoKeyLocation:{
        width: "100%",
        display: "flex",
        flexDirection:"column",
        margin: "10px"
    },
    equipmentInfoValue:{
        width:"50%",
        display: "flex",
        flexDirection:"column",
        margin: "10px"
    },
    equipmentKey:{
        margin:"3px"
    },
    equipmentValue:{
        margin:"3px",
        fontWeight:"normal",
        background:"#ebebeb",
        borderRadius:"4px",
        padding:"4px"
    },
    equipmentValueMobile:{
        margin:"3px",
        fontWeight:"normal",
        background:"#ebebeb",
        borderRadius:"3px",
        padding:"5px",
        fontSize:"15px"
    },
    equipTelemetry:{
        marginBottom:"25px"
    },
    equipTelemetryValues:{
        display:"flex",
        justifyContent:"space-between",
        flexDirection:"row"
    },
    equipWaterlvl:{
        margin:"0px 15px",
        fontSize:"1.3rem",
        fontWeight:"normal"
    },
    equipFuelLvl:{
        margin:"0px 15px",
        fontSize:"1.3rem",
        fontWeight:"normal"
    },
    equipTempLvl:{
        margin:"0px 15px",
        fontSize:"1.3rem",
        fontWeight:"normal"
    },
    equipmentInfoContainer:{
        margin: "0px 10px"
    },
    equipImageCard:{
        display: "flex",
        justifyContent: "space-evenly",
    }, 
    equipmentListImage:{
        borderRadius:"10px"
    },
    equipDetailCard:{
        padding:"0px"
    },
    equipmentContentCard:{
        padding:"0px",
        "&:last-child": {padding: "0px"},
        display:"flex",
        flexDirection:"row"
    },
    cardbuttonContainer:{
        display:"flex",
        justifyContent:"space-between",
        marginLeft:"auto"
    },
    cardButton:{
        fontSize:"0.8rem",
        color:"#0EBC2A",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
    },
    cardMoreInfoContainer:{
        display:"flex",
        justifyContent:"space-between"
    },
    cardMobilebtnStyle:{
        // marginTop:"-39px"
    },
    cardbtnStyle:{
        padding:"0px 0px"
    },
    tagNameContainer:{
        display:"flex"
    },
    tagName:{
        // background:"#75E97A",
        color:"black",
        borderRadius:"4px",
        padding:"0px",
        fontSize:"12px",
        fontWeight:"bold",
        lineHeight:"1rem"        
    },
    equipmentTitleList:{
        color:"#494949",
        fontSize:"25px",
        lineHeight:"1.4"
    },
    equipmentCategoryTitle:{
        fontWeight:"bold",
        fontSize:"0.7rem",
        color:"#636363",
        lineHeight:"1.2"
    },
    equipmentCategoryDetails:{
        fontSize:"1rem",
        lineHeight:"1.2",
        fontWeight:"800",
        color:"#494949"
    },
    editIcon:{
        color:"#636363",
        padding:"0px",
        fontSize:"1rem",
        height:"60%",
        marginLeft:"5px"
    },
    notificationIcon:{
        
    },
    detailCard:{
        margin:"20px 20px 0px 20px",
        background:"white",
        // height:"100%"
    },
    detailCardMobile:{
        // margin:"5px 10px 0px 10px",
        background:"white"
    },
    detailItemCard:{
        background:"white",
        borderRadius:"10px",
        // margin:"15px",
        // height:'inherit',
        padding:"0px 40px",
        // boxShadow:"0px 0px 15px 2px #cbcaca"
    },
    detailItemListCard:{
        background:"white",
        borderRadius:"5px",
        // margin:"15px",
        // height:'100%',
        // padding:"20px",
        boxShadow:"0px 0px 15px 2px #cbcaca"
    },
    detailItemCardMobile:{
        background:"white",
        borderRadius:"10px",
        margin:"10px",
        padding:"10px",
        boxShadow:"0px 0px 15px 2px #cbcaca"
    },
    detailTitle:{
        fontSize:"20px",
        fontFamily:"Roboto",
        fontStyle:"normal",
        fontWeight:"500",
        color:"#575757"
    },
    detailDescription:{
        background:"#DFF0FF",
        fontSize:"15px",
        fontWeight:"bold", 
        marginRight:"15px", 
        padding:"6px", 
        borderRadius:"8px", 
        display:"flex", 
        justifyContent:"center", 
        width:"100%"
    },
    detailKeyValueCard:{
        paddingBottom:"16px",
        width:'100%'
    },
    descriptionDetailPage:{
        margin:"0px 0px"
    },
    arrowBack:{
        // marginTop: "10px",
        // marginLeft: "10px",
        border: "none",
        background: "none",
        color:"#00005a",
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        cursor:"pointer"
    },
    backArrowStyle:{
        fontSize:"1.9rem"
    },
    loadingBtn:{
        display:"flex",
        justifyContent:"center"
    },
    landingPageContainer:{
        display:"flex",
        justifyContent:"center"
    },
    editBtnStyle:{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column"
    },
    swipperClass: {
        backgroundColor:'black !important',
    },
    swipperPagination:{
        backgroundColor: 'white !important',
    },
    main:{
        display:"grid",
        gap:"2rem",
        cursor:"pointer",
        padding:"1.5em 0"
      },
    card: {
        borderRadius:"5px",
        boxShadow:"0px 4px 3px rgba(0, 0, 0, 0.1)"
    },
    cardimg:{
    objectFit:"cover",
    },
    cardinfo:{
        WebkitUserSelect:"none",
        MozUserSelect:"none",
        msUserSelect:"none",
        userSelect:"none",
        padding:"1em 1em 2em"
    },
    drawerForm:{
      width:'34%'
    },
    drawerFormMobile:{
     width:'100%'
    },
    drawerFormTablet:{
     width:'65%'
    },
    popupStyle:{
        width: "232px"
    }
    
});