
const progressFn = (percentVal: any, defTelemetry?: any) => {
  if(defTelemetry){
      const telVal = percentVal?.filter((res: any) => {
        const sensorObject = res?.value.toLowerCase() === defTelemetry?.value.toLowerCase();
        return sensorObject
      })
      return Math.floor(telVal[0]?.valueNormalised * 100);
  } else {
    const valueNormalised = percentVal?.valueNormalised > 1.0 ? 1.0 : percentVal?.valueNormalised
    return Math.floor(valueNormalised * 100);
  }
}

export default progressFn