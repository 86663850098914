import MapLeaflet from "../components/MapLeaflet/MapLeaflet";
import { TileLayer, MapContainer, Marker, Popup } from "react-leaflet";
import { Alert, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import { useStyle } from "../styles/stylemenu";
import { GetMapInfo } from "./../hooks/GetMapInfo";
import { getMapObjects } from "../store/features/mapAction"
import { EquipmentMapInfo } from "../../src/interfaces/EquipmentMapInfo";
import * as React from "react";
import { Error } from '../components/Error/Error';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from "../store/store";
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { useState } from "react";
import { LoaderComponent } from "../components/LoaderComponent/LoaderComponent";
const center = { lat: 60.472023, lng: 8.468946 };
const style = {
  marginTop:"20%"
};

const styleMobile = {
  marginTop:"72%"
};



export const Dashboard = () => {
  const location = useLocation();
  const state = location.state
  const matches = useMediaQuery('(max-width:600px)');
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  //Redux
  const dispatch: AppDispatch = useAppDispatch();
  // dispatch(getMapObjects())
  const objectsList = useAppSelector((stateS: RootState) => {
    const equipments = JSON.parse(JSON.stringify(stateS.mapObjects.data || []));
    const alerts = JSON.parse(JSON.stringify(stateS.notification.data || []));

    equipments.forEach((eq:any) => {
      eq.alerts = alerts.find((a:any) => a.objectId.toLowerCase() === eq.objectId.toLowerCase())?.alerts;
    });

    return equipments;

  });
  const loading = useAppSelector((stateS: RootState) => stateS?.mapObjects?.loading);
  const error = useAppSelector((stateS: RootState) => stateS?.mapObjects?.error);
 



  React.useEffect(() => {
    if (loading) {
      setIsLoading(false);
        //fetching Datas to display it
       
    }
    dispatch(getMapObjects())

  }, []);
  const noDetailsDataFound = () => {}
  if(loading){
    return (
    <Grid item pt={5} display={'flex'} justifyContent={'center'} flexDirection={'row'} height={'100%'}>
      <LoaderComponent/>
      <Typography variant="h6" style={matches ? styleMobile : style}>{t('please wait, fetching data')}</Typography>
    </Grid>
    )
  }

  return (
  
    <div style={{ backgroundColor: "red", height: "100%" }}>
  {
      error ? <Error isOpen = {true} message = {error} noDetailsDataFound={noDetailsDataFound}/> : ''
    }

                      <MapLeaflet  equipments = {objectsList}/>
                   
                
         
    </div>

  );
};
