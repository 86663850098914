import { axiosInstance } from "../authConfig";

const baseUrl = process.env.REACT_APP_BASE_URL;
const imgBaseUrl = process.env.REACT_APP_IMG_URL;

let axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Ocp-Apim-Subscription-Key": "3d15850fbc5641029aa1aa05acd431cb",
  }
};

let formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    "Ocp-Apim-Subscription-Key": "3d15850fbc5641029aa1aa05acd431cb",
  },
}


export const getEquipmentInfoForMap = (): Promise<any> => {
  return axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment?scope=${'map'}&pgNum=-1&pgSize=500`, axiosConfig).then((res) => {
    let s = res.data.data.map((item: any) => ({
      objectId: item.objectId,
      name: item.name,
      idTag: item.idTag,
      type: item.type,
      assignedLocation: item.assignedLocation,
      operationState: item.operationState,
      operationStateString:item.operationStateString,
      waterLevel: item.telemetry?.value,
      geoCoordinates: item.geoCoordinates,
      telemetry: item.telemetry,
      defaultTelemetry: item.defaultTelemetry,
      images: item.images,
      connectionState: item.connectionState
    })
    );
    return s;
  })
    .catch((err) => {
      console.log(err);
    });
};

export const GetAllEquipments = async (pgSize?: any, pgNum?: any): Promise<any> => {
    return await axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment?scope=${'grid'}&pgNum=${pgNum}&pgSize=${pgSize}`, axiosConfig).then((res: any) => {
    return res.data;
  }).catch((err: any) => {
    if(err.response.status === 401){
    }
    throw err;
    // return err
  });
};

export const getAccess = async (pgSize?: any, pgNum?: any): Promise<any> => {
  return await axiosInstance.get(`${baseUrl}/scanwater/prepdash/myaccess`, axiosConfig).then((res: any) => {
   
  return res.data;
}).catch((err: any) => {
  if(err.response.status === 401){
  }
  throw err;
  // return err
});
};

export const GetEquipmentDetail = async (id: any): Promise<any> => {
  return await axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment/${id}/details`, axiosConfig).then((res) => {
    return res.data;
  }).catch((err: any) => {
    throw err
    // return err
  });
};

export const getTelemetryInformation = async (id: any): Promise<any> => {
  return await axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment/${id}/telemetry`, axiosConfig).then((res) => {
    return res.data;
  }).catch((err: any) => {
    throw err
  });
};

export const deleteEquipments = async (id: any): Promise<any> => {
  return await axiosInstance.delete(`${baseUrl}/scanwater/prepdash/equipment/${id}`, axiosConfig).then((res) => {
    return res.data;
  }).catch((err: any) => {
    return err
  });
};

export const getAllNotifications = async (): Promise<any> => {
  return await axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment/adt/notifications`, axiosConfig).then((res) => {
   
    return res.data;
  }).catch((err) => {
    // console.log(err.toJSON());
    return err;
  });
}

export const getDropDownList = (): Promise<any> => {
  return axiosInstance.get(`${baseUrl}/scanwater/prepdash/equipment/dropdownlist`, axiosConfig).then((res: any) => {
    return res.data;
  }).catch((err) => {
    console.log(err.toJSON());
  });
}

export const mapInformation = async () => {
  try {
    const response = await Promise.all([getEquipmentInfoForMap(), getAllNotifications()]);
    const mapItems = response[0];
    const notif = response[1];
    const resultMapItems: any = [];

    mapItems.map((res: any) => {
      return resultMapItems.push({ ...res, ...notif.find((notifications: any) => notifications.objectId === res.objectId) });
    });
  // console.log(resultMapItems)
    return resultMapItems;
    
  } catch (error: any) {
    console.log(error)
  }
}

export const getImgUri = async (img: any) => {
  return await axiosInstance.get(`${imgBaseUrl}=${img}`, axiosConfig
  ).then((res: any) => {
    return res.data;
  });
}

const retry = async (times: number, invoke: () => Promise<any>) => {
  for (let i = 0; i < times; i++) {
    try {
      await invoke();
      break;
    } catch (e) {
      if (i === times - 1) {
        throw e;
      }
      await new Promise(r => setTimeout(r, 500));
    }
  }
}

export const postImageData = async (imgData: any) => {
  return await axiosInstance.post(`${baseUrl}/scanwater/prepdash/equipment/image`, imgData, formDataConfig)
    .then((res: any) => {
      return res.data;
    }).catch((error) => {
      return error;
    });;
}

export const postEquipmentDetails = async (equipData: any) => {
  return await axiosInstance.post(`${baseUrl}/scanwater/prepdash/equipment`, equipData, axiosConfig
  ).then((res: any) => {
    return res.data;
  }).catch((error) => {
    return error;
  });;
}

export const updateEquipmentDetails = async (equipData: any) => {
  let equipmentId = equipData.objectId
  return await axiosInstance.put(`${baseUrl}/scanwater/prepdash/equipment/${equipmentId}`, equipData, axiosConfig
  ).then((res: any) => {
    return res.data;
  }).catch((error) => {
    return error;
  });;
}
export const updateImages = async (imgData: any) => {
  console.log(imgData)
  let equipmentId = imgData.id
let images ={images:imgData.images} 
// console.log(images)
  return await axiosInstance.put(`${baseUrl}/scanwater/prepdash/equipment/${equipmentId}/image`, images, axiosConfig
  ).then((res: any) => {
    return res.data;
  }).catch((error) => {
    return error;
  });;
}
export const deleteEquipment = async (equipData: any) => {
  let equipID = equipData.objectId

  return await axiosInstance.delete(`${baseUrl}/scanwater/prepdash/equipment/${equipID}`, axiosConfig
  ).then((res: any) => {
    return res.data;
  }).catch((error) => {
    return error;
  });;
}
export const deleteImage = async (equipData: any) => {
  console.log(equipData)
  let equipID = equipData.id

  return await axiosInstance.delete(`${baseUrl}/scanwater/prepdash/equipment/${equipID}/image`, axiosConfig
  ).then((res: any) => {
    return res.data;
  }).catch((error) => {
    return error;
  });;
}
export const getImageSassUrl = async (imgData: any) => {
  return await axiosInstance.post(`${baseUrl}/images/sasuri`, imgData, axiosConfig).then((res: any) => {
    console.log(res);
    return res.data;
  }).catch((error) => {
    console.log(error.data);
  });;
}

