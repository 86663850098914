import { configureStore } from "@reduxjs/toolkit";
import mapObjectsSlice from "./features/mapObjectsSlice";
import notificationSlice from "./features/notificationSlice"
import  objectSlice  from "./features/Equipment/equipmentSlice";
import logger from 'redux-logger';
import telemetrySlice from "./features/Telemetry/telemetrySlice";
import slice from "./features/Equipment Detail/slice";

export const store = configureStore({
    reducer:{ 
        objects: objectSlice,
        mapObjects: mapObjectsSlice,
        telemetry: telemetrySlice,
        notification:notificationSlice,
        details: slice
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;