import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import England from "../../../assets/England.png";
import Norway from "../../../assets/norway.png";
import Sweden from "../../../assets/sweden.png";
import { Avatar, Badge, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { ref } from 'yup';
export default function SelectLabels() {
  const [menu, setMenu] = React.useState('no');

  const handleChange = (event: SelectChangeEvent) => {
    setMenu(event.target.value);
  };
  const { t, i18n, ready } = useTranslation();
  const changeLanguage = (lng: any) => { 
    i18n.changeLanguage(lng);
  };
  return (
    <>



      <Tooltip title={t('selectlangauge')} disableInteractive={true}>
        <FormControl sx={{ m: 1, width: '100%', maxWidth: 360 }}>

          <Select
            value={menu}
            onChange={handleChange}
            displayEmpty={false}
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, width:"165px"}}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={"no"} onClick={() => changeLanguage("no")} style={{padding:"0px !important"}}>
              <div style={{display:'flex'}}>
                <ListItemIcon>
                  <img src={Norway} style={{ width: 20, height: 20, }} alt="Norway" loading="lazy"/>
                </ListItemIcon>
                <ListItemText disableTypography primary="Norway"  style={{margin:'0px'}}/>
               </div>
            </MenuItem>
            <MenuItem value={"se"} onClick={() => changeLanguage("se")}>
              <div style={{display:'flex'}}>
                <ListItemIcon>
                  <img src={Sweden} style={{ width: 20, height: 20 }} alt="Sweden" loading="lazy"/>
                </ListItemIcon>
                <ListItemText disableTypography primary="Svenska"  style={{margin:'0px'}}/>
              </div>
            </MenuItem>
            <MenuItem value={"en"} onClick={() => changeLanguage("en")}>
              <div style={{display:'flex'}}>
                 <ListItemIcon style={{minWidth:'42px'}}>
                  <img  src={England} style={{ width: 20, height: 20 }} alt="English" loading="lazy"/>
                </ListItemIcon>
                <ListItemText disableTypography primary="English" style={{margin:'0px'}} />
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </>
  );
}
