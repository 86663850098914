import React from "react";

import LogoImg from '../../assets/MW_white_alone.png';
import styled from "@emotion/styled";


interface Props {
  logoSize?: any;
  textSize?: any;
  color?: any;
  hideLogo?: any;
  size?: any
}

const BrandLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.div<Props>`
  // width: ${({ sizee }: any) => (sizee ? sizee + "px" : "0px")};
  height: ${({ size }: any) => (size ? size + "px" : "1em")};
  img {
    height: 90%;
  }
`;

const LogoTitle = styled.h2<Props>`
  margin: 0;
  font-size: ${({ size }: any) => (size ? size + "px" : "30px")};
  color: ${({ color }) => (color ? color : "#fff")};
  font-weight: 900;
  margin-left: 15px;
`;

export function BrandLogo(props: Props) {
  const { logoSize, textSize, color, hideLogo } = props;

  return (
    <BrandLogoContainer>
      {!hideLogo && (
        // <Link to="/">
          <LogoImage size={logoSize}>
            <img src={LogoImg} alt="MW" />
          </LogoImage>
        // </Link>
      )}
      {/* <StyledLink to="/"> */}
        {/* <LogoTitle size={textSize} color={color}>
        </LogoTitle> */}
      {/* </StyledLink> */}
    </BrandLogoContainer>
  );
}