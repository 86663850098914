
const timestamp = (date: Date, t:(key:string) => string) => {
    const seconds = Math.floor((+new Date() - +(date)) / 1000);
    
    let x = Math.floor(seconds / 31536000) > 1 ? Math.floor(seconds / 31536000) + ` ${t('years')} ${t('ago')}`  :
    Math.floor(seconds / 2592000)  > 1 ? Math.floor(seconds / 2592000) + ` ${t('months')} ${t('ago')}` :
    Math.floor(seconds / 86400)    > 1 ? Math.floor(seconds / 86400) + ` ${t('days')} ${t('ago')}` :
    Math.floor(seconds/ 3600)      > 1 ? Math.floor(seconds/ 3600) + ` ${t('hours')} ${t('ago')}`:
    Math.floor(seconds/ 60)        > 1 ? Math.floor(seconds/ 60)  + ` ${t('minutes')} ${t('ago')}` :
    seconds < 10  ? ` ${t('just now')}` : ` ${t('seconds')} ${t('ago')}`;
    
  return x;
}

export default timestamp